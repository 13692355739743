import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques

// Composants propres
import LEVEL_CLASSNAMES  from '../lib-enums/E_LevelClassNames';

// Composants primitves

// Styles
import './MyIngredientCard.scss';

type MyIngredientCardProps = {
  /** Nom */
  title: any;
  /** Indication du milieu */
  mediumLabel?: any;
  /** Indication du bas */
  bottomLabel?: any;
  /** Color */
  alertClassName?: LEVEL_CLASSNAMES;
  /** Custom flag */
  customFlag?: boolean;
  /** ClasName additionnelle */
  className?: string;
  /** Callback au click */
  onClick?: any;
}

/**
 * Affiche une card Ingredient.
 * @param props 
 * @returns {MyIngredientCard}
 */
function MyIngredientCard(props:MyIngredientCardProps) {

  // props
  let {title,mediumLabel,bottomLabel,alertClassName,customFlag,className,onClick} = props;

  // state

  // autres variables

  return (
    <div className="ingredient" onClick={onClick}>
      <div className={'ingredientCell '+alertClassName+(!!customFlag ? ' customRef' : '')+' '+className}>
        <h2>{title}</h2>
        <div><small>{mediumLabel}</small></div>
        <div><small><i>{bottomLabel}</i></small></div>
      </div>
    </div>
  );
}

export default MyIngredientCard;
