import {I_Barcode}    from '../lib-interfaces/I_Barcode';

/**
 * Represente un plat.
 * 
 * 
 * @folder Lib/Classes
 */
class BarcodeClass {

  /** 
   * Identifiant de la référence 
   * @type number
   */   
   idBarcode: number;
  /** 
   * Libellé de la référence en francais 
   * @type string
   */
  label_fr?: string;
  /** 
   * Marque
   * @type string
   */
  trademark?: string;
  /** 
   * Age minimal recommandé
   * @type number
   */
  age?: number;
  /** 
   * Liste des id d'ingredients
   * @type number[]
   */
  idIngredients: number[];

  /** Indique si la reference provient de l'app ou entree manuellement */
  private _isCustom: boolean =false;        // false si c'est une data en dur, true si c'est une data entree par l'utilisateur (et donc en localStorage)

  /**
   * Constructeur
   * @param {I_Barcode} refObject
   * @param {boolean} isCustom
   */
  constructor(refObject:I_Barcode, isCustom:boolean) {
    if(!refObject){
      throw new Error('Cannot instanciate BarcodeClass with undefined refObject');
    }
    this.idBarcode = refObject.idBarcode;
    this.label_fr = refObject.label_fr;
    this.trademark = refObject.trademark;
    this.age = refObject.age;
    this.idIngredients = refObject.idIngredients;
    this._isCustom = isCustom;
  }

  /**
   * 
   * @returns {object} Renvoi l'object json de donnees de reference
   */
  getRefObject() {
    return {
      idBarcode: this.idBarcode,
      label_fr: this.label_fr,
      trademark: this.trademark,
      age: this.age,
      idIngredients: this.idIngredients
    };
  }

  /**
   * 
   * @returns {boolean} Renvoi true si c'est un ingredient entre par l'utilisateur (et donc en localStorage), false si c'est un ingredient en dur dans l'app
   */
  isCustom():boolean {
    return this._isCustom;
  }

  toJson() {
    return {
      idBarcode: this.idBarcode,
      label_fr: this.label_fr,
      trademark: this.trademark,
      age: this.age,
      idIngredients: this.idIngredients,
      _isCustom: this._isCustom
    };
  }


  
}

export default BarcodeClass;
