import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

// Composants génériques
import S_Date     from '../../services/S_Date';

// Composants propres
import FeedingClass    from '../lib-classes/FeedingClass';
import FeedingAsLine    from '../ui-objects/FeedingAsLine';

// Styles
import './FeedingsListOfADay.scss';

type FeedingsListOfADayProps = {
  /**
   * Date (in millis) des repas à afficher.
   * @default Date courante
   */
  dayInMillis: number;        // day in millis
  /**
   * Repas à afficher
   * @default []
   */
  feedings: FeedingClass[];
  /** Fonction appelée sur l'action d'édition */
  editFeeding?: any;
}

/**
 * Affiche un tableau présentant les repas pour un jour donné.
 */
function FeedingsListOfADay(props:FeedingsListOfADayProps) {

  // props
  let {dayInMillis=(new Date()).getTime(),feedings=[],editFeeding} = props;
  
  // state

  // chargement des donnees

  // autres variables
  let day = 'Date non définie';
  if(dayInMillis!==-1) day = S_Date.formatDateInStringWithName(dayInMillis);



  return (
    <div className="day">
      <div className="dayCell">
        <div className="dayHeader">
          <strong><CalendarTodayIcon /> {day}</strong>
          <span className="float-right"><small><i>{feedings.length} repas</i></small></span>
        </div>
        {feedings.length > 0 ? (
        <div className="dayBody">
          {feedings.map((feeding,index) => {
            return (
              <FeedingAsLine key={index+'_'+feeding.idFeeding}
                feeding={feeding}
                editFeeding={() => editFeeding(feeding)}/>
            );

          })}
        </div>
        ) : (
        <div className="dayBody">
          Aucun repas
        </div>
        )}
      </div>
    </div>
  );
}

export default FeedingsListOfADay;
