import React,{useEffect,useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@mui/material/Container';
import Fab       from '@mui/material/Fab';

import AddIcon   from '@mui/icons-material/Add';


// Composants génériques

// Composants propres
import FeedingClass         from '../lib-classes/FeedingClass';
import FeedingsListByDays         from '../ui-lists/FeedingsListByDays';

// Styles
// import './PanelFeedings.scss';

type PanelFeedingsProps = {
  feedings: FeedingClass[];
  addFeeding: any;
  editFeeding: any;
}

function PanelFeedings(props:PanelFeedingsProps) {

  // props
  let {feedings,addFeeding,editFeeding} = props;

  // state

  // autres variables

  return (
    <Container fixed className="panel-contents pb-6">

      <div className="mt-vague">
        <FeedingsListByDays 
          feedings={feedings}
          addFeeding={addFeeding}
          editFeeding={editFeeding} />
      </div>
      
      <div className="panel-contents-footer text-right">
        <Fab aria-label="Ajouter" onClick={addFeeding} className="mh-2" color="primary"><AddIcon /></Fab>
      </div>

    </Container>
  );
}

export default PanelFeedings;
