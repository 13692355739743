import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Button from '@mui/material/Button';

import ShareIcon from '@mui/icons-material/Share';

// Composants génériques

// Composants propres
import S_Cross         from '../lib-services/S_Cross';
import IngredientClass   from '../lib-classes/IngredientClass';
import IngredientAsInlineLabel from '../ui-objects/IngredientAsInlineLabel';

// Styles
import './IngredientsSummary.scss';

type IngredientsSummaryProps = {
  ingredients: IngredientClass[];
}

/**
 * 
 * @param props 
 * @returns {IngredientsSummary}
 */
function IngredientsSummary(props:IngredientsSummaryProps) {

  // props
  let {ingredients} = props;

  // state

  // autres variables

  const ingredientsGroupedByAlertLevel = S_Cross.getIngredientsGroupedByAlertLevel(ingredients);
  const ingredientsOK = ingredientsGroupedByAlertLevel.ingredientsOK;
  const ingredientsKO = ingredientsGroupedByAlertLevel.ingredientsKO;
  const ingredientsMaybeOK = ingredientsGroupedByAlertLevel.ingredientsMaybeOK;
  const ingredientsMaybeKO = ingredientsGroupedByAlertLevel.ingredientsMaybeKO;

  function handleShare(){
    const contents = S_Cross.generateShareIngredientsListsText(ingredientsGroupedByAlertLevel);

    let navigatorSystem: any;
    navigatorSystem = window.navigator;
    if(navigatorSystem && navigatorSystem.share) {
      navigatorSystem.share({
        title: 'Diversification alimentaire',
        text: contents
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    }else{
      console.log('Le navigateur ne prend pas en charge le partage.',contents);
    }
  }

  return (
    <div className="ingredientsSummary">
      {ingredientsOK.length>0 ? (
      <div className="mv-1">
        {ingredientsOK.map((ingredient) => <IngredientAsInlineLabel key={ingredient.idIngredient} ingredient={ingredient} />)}
      </div>
      ) : null}
      {ingredientsMaybeOK.length>0 ? (
        <div className="mv-1">
          {ingredientsMaybeOK.map((ingredient) => <IngredientAsInlineLabel key={ingredient.idIngredient} ingredient={ingredient} />)}
        </div>
      ) : null}
      {ingredientsMaybeKO.length>0 ? (
      <div className="mv-1">
        {ingredientsMaybeKO.map((ingredient) => <IngredientAsInlineLabel key={ingredient.idIngredient} ingredient={ingredient} />)}
      </div>
      ) : null}
      {ingredientsKO.length>0 ? (
      <div className="mv-1">
        {ingredientsKO.map((ingredient) => <IngredientAsInlineLabel key={ingredient.idIngredient} ingredient={ingredient} />)}
      </div>
      ) : null}

      <div className="text-center mv-2">
        <Button variant="outlined" onClick={handleShare} color="primary" startIcon={<ShareIcon />}>Partager</Button>
      </div>
    </div>
  );
}

export default IngredientsSummary;
