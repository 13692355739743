import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@mui/material/Container';
import Fab       from '@mui/material/Fab';
import Button from '@mui/material/Button';

import AddIcon   from '@mui/icons-material/Add';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';


// Composants génériques

// Composants propres
import BarcodeClass      from '../lib-classes/BarcodeClass';
import BarcodesList          from '../ui-lists/BarcodesList';

// Styles
import './PanelBarcodes.scss';

type PanelBarcodesProps = {
  barcodes: BarcodeClass[];
  addBarcode: any;
  editBarcode: any;
  scan: any;
}

function PanelBarcodes(props:PanelBarcodesProps) {

  // props
  let {barcodes,addBarcode,editBarcode,scan} = props;

  // state

  // autres variables

  return (
    <Container fixed className="panel-contents pb-6">

      <div className="mt-vague">
        <h2 className="primary">Gestion des codes barres</h2>
        <div>
          <p>Pour éviter de devoir sélectionner les ingrédients d'un plat industriel que vous ouvrez souvent, mémorisez-le dans la liste des codes barres. 
          Un simple scan du code barre sélectionnera automatiquement les ingrédients par la suite.</p>
        </div>
        <div className="text-center mv-2">
          <Button variant="outlined" onClick={scan} color="primary" startIcon={<DocumentScannerIcon />}>Scanner un code barre</Button>
        </div>
        <div className="text-center mv-2">
          <Button variant="outlined" onClick={addBarcode} color="primary" startIcon={<AddIcon />}>Ajouter un plat</Button>
        </div>
      </div>

      <div className="mt-2">
        <BarcodesList
          barcodes={barcodes}
          addBarcode={addBarcode}
          scanBarcode={scan}
          editBarcode={editBarcode} />
      </div>
      
      <div className="panel-contents-footer text-right">
        <Fab aria-label="Ajouter" onClick={addBarcode} className="mh-2" color="primary"><DocumentScannerIcon /></Fab>
      </div>

    </Container>
  );
}

export default PanelBarcodes;
