import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques

// Composants propres
import LEVEL_CLASSNAMES  from '../lib-enums/E_LevelClassNames';

// Styles
import './MyFlag.scss';

type MyFlagProps = {
  /** Color */
  alertClassName?: LEVEL_CLASSNAMES;
  /** First */
  first?: boolean;
}

/**
 * Affiche un flag.
 * @param props 
 * @returns {MyFlag}
 */
function MyFlag(props:MyFlagProps) {

  // props
  let {alertClassName,first} = props;

  // state

  // autres variables


  return (
    <div className={'feed ' + alertClassName + ' ' + (!!first ? 'firstTime' : null)}></div>
  );
}

export default MyFlag;
