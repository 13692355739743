import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques

// Composants propres
import LEVEL_CLASSNAMES  from '../lib-enums/E_LevelClassNames';

// Styles
import './MyInlineLabel.scss';

type MyInlineLabelProps = {
  /** Label */
  label?: any;
  /** Color */
  alertClassName?: LEVEL_CLASSNAMES;
  /** Disabled */
  disabled?: boolean;
  /** Classname */
  className?: string;
}

/**
 * Affiche un label en ligne.
 * @param props 
 * @returns {MyInlineLabel}
 */
function MyInlineLabel(props:MyInlineLabelProps) {

  // props
  let {label,className,alertClassName,disabled} = props;

  // state

  // autres variables

  if(!label)
    return null;

  return (
    <span className={'myInlineLabel '+alertClassName+ ' '+(!!disabled?'disabled':'')+' '+className}>{label}</span>
  );
}

export default MyInlineLabel;
