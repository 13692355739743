import React,{useState,useEffect} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddIcon   from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// Composants génériques
import S_App      from '../../services/S_App';
import { Scanner } from 'react-mui-pwa-tools';

// Composants propres
import S_Barcode          from '../lib-services/S_Barcode';
import IngredientClass     from '../lib-classes/IngredientClass';
import BarcodeAsCard            from '../ui-objects/BarcodeAsCard';

// Styles
import './DialogScan.scss';

type DialogScanProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickSave: any;
  onDeleteBarcode: any;
  dialogScanAdd: boolean;
  addIngredientsToFeeding: any;
  storedIngredients: IngredientClass[];
  editBarcode: any;
}

function DialogScan(props:DialogScanProps) {

  // props
  let {isDialogOpened,closeDialog,onClickSave,onDeleteBarcode,dialogScanAdd,addIngredientsToFeeding,storedIngredients,editBarcode} = props;

  // state
  const [barcode, setBarcode] = useState(undefined);
  const [barcodeObject, setBarcodeObject] = useState(undefined);
  const [ingredients, setIngredients] = useState([]);
  const [verified, setVerified] = useState(false);
  const [name, setName] = useState('');

  // chargement des donnees
  useEffect(() => {
    // console.log('-- DialogScan useEffect --');

    setBarcode(undefined);
    setBarcodeObject(undefined);
    setIngredients([]);
    setVerified(false);
    setName('');
    
  }, []);




  const onDetected = result => {
    if(!!result){
      setBarcode(result);
    }
  };

  function onScanned(){
    let barcodeObj = S_Barcode.getById(parseInt(barcode));
    console.log('-- onScanned -- barcodeObj',barcodeObj);
    setBarcodeObject(barcodeObj);
    setVerified(true);
  }

  function changeIngredients(value){
    setIngredients(value);
  }
  function changeName(value){
    setName(value);
  }

  function close(){
    setBarcode(undefined);
    setBarcodeObject(undefined);
    setIngredients([]);
    setVerified(false);
    closeDialog();
  }

  function deleteBarcode(){
    onDeleteBarcode(barcode);
    setBarcodeObject(undefined);
    setIngredients([]);
    setVerified(false);
  }

  function save(){
    onClickSave(barcode,ingredients,name);
    setBarcodeObject(undefined);
    setIngredients([]);
    setVerified(false);
  }

  function addIngredients(){
    addIngredientsToFeeding(barcodeObject.ingredients);

    setBarcode(undefined);
    setBarcodeObject(undefined);
    setIngredients([]);
    setVerified(false);
  }

  let storedIngredientsOrdered = S_App.sortByLabel(storedIngredients);


  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus>
      <DialogTitle id="alert-dialog-title">Scanner un code barre</DialogTitle>
      <DialogContent>
        
        <Scanner 
          onDetected={onDetected} 
          onReset={() => {}}/>

        {!!barcode ? (
          <div className="text-center">
            <h3>{barcode}</h3>
            {!verified ? (
              <Button variant="outlined" onClick={() => onScanned()} color="primary">Vérifier</Button>
            ) : (
              <div>
                {!!barcodeObject ? (
                  <h2 className="success"><CheckIcon /> Trouvé !</h2>
                ) : (
                  <h2 className="danger"><ErrorOutlineIcon /> Non trouvé.</h2>
                )}
              </div>
            )}
          </div>
        ) :null}
        

        {verified ? (
          <>

            {!!barcodeObject ? (
            <>
            <div className="mv-2">
              <BarcodeAsCard
                barcode={barcodeObject}
                editBarcode={barcode.isCustom() ? () => editBarcode(barcode) : undefined}/>
            </div>
            {dialogScanAdd ? (
              <div className="mv-1"><Button variant="outlined" onClick={addIngredients} color="primary" startIcon={<AddIcon />} fullWidth>Ajouter au repas</Button></div>
            ) : null}
            {false ? <Button variant="outlined" onClick={deleteBarcode} color="primary" startIcon={<DeleteForeverIcon />} fullWidth>Supprimer</Button> : null}
            </>
            ) : (
            <div className="mv-2">
              <div className="mv-1">
                <TextField id="name" label={'Nom de la barquette'} type="text" fullWidth size="small"
                  value={name}
                  onChange={(e) => changeName(e.target.value)} />
              </div>
              <div>Sélectionner les ingrédients</div>
              {!!storedIngredientsOrdered && storedIngredientsOrdered.length>0 ? (
                <>
                <FormControl fullWidth>
                  <Select labelId="ingredients" id="ingredients" value={ingredients} label="Aliments" onChange={(e) => changeIngredients(e.target.value as string)} multiple={true}>
                    {storedIngredientsOrdered.map((ingredient,index) => {
                      return (
                        <MenuItem key={index} value={ingredient.id}>{ingredient.label_fr}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <div className="mv-2">
                  <Button startIcon={<AddIcon />} onClick={save} color="primary" variant="outlined" fullWidth>Enregistrer</Button>
                </div>
                </>
              ) : (
                <div className="text-center">
                  Aucun ingrédient enregistré.
                </div>
              )}
            </div>

            )}

          </>
        ) : null}

      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogScan;
