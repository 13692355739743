/**
 * @enum {string}
 * @folder Lib/Enums
 */
 enum LEVEL_CLASSNAMES {
   /** Non selection */
  UNDEFINED = '',
  /** A priori OK */
  LIGHTSUCCESS = 'lightsuccess',
  /** A surveiller */
  LIGHTWARNING = 'lightwarning',
  /** OK */
  SUCCESS = 'success',
  /** A surveiller */
  WARNING = 'warning',
  /** A eviter */
  DANGER = 'danger'
};
export default LEVEL_CLASSNAMES;