import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@mui/material/Container';

// Composants génériques
import S_App      from '../../services/S_App';
import { Saves } from 'react-mui-pwa-tools';
import { Version } from 'react-mui-pwa-tools';
import { Credits } from 'react-mui-pwa-tools';

// Composants propres
import S_Feeding     from '../lib-services/S_Feeding';
import S_Ingredient  from '../lib-services/S_Ingredient';
import S_Barcode     from '../lib-services/S_Barcode';

// Styles
// import './PanelConfig.scss';

import pkg from '../../../package.json';

type PanelConfigProps = {
  
}

function PanelConfig(props:PanelConfigProps) {

  // props
  // let {} = props;

  // state

  // chargement des donnees

  // autres variables
  const isDEV = !!process.env && process.env.NODE_ENV === 'development';

  return (
    <Container fixed className="panel-contents">
      
      <Saves 
        storageKeyPrefix={S_App.STORAGE_KEY_PREFIX}
        storageKeys={[S_Feeding.STORAGE_KEY_FEEDING_CONTEXT,S_Ingredient.STORAGE_KEY_INGREDIENT,S_Barcode.STORAGE_KEY_BARCODE]}
        shareTitle="Dashboard diversification"
        className="mt-vague" />

      <Version
        development={isDEV}
        version={pkg.version}
        lastUpdate={pkg.lastUpdate} />

      <Credits>
        Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
      </Credits>
      
    </Container>
  );
}

export default PanelConfig;
