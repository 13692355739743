import S_App          from   '../../services/S_App';
import FeedingClass from   '../lib-classes/FeedingClass';
import {I_FeedingContext} from   '../lib-interfaces/I_Feeding';
import LEVEL_CLASSNAMES  from '../lib-enums/E_LevelClassNames';

interface I_S_Feeding {
  STORAGE_KEY_FEEDING_CONTEXT: string;
  feedings: FeedingClass[];

  fetch(cb): any;
  store(cb): any;

  getById(idIngredient:number|string): FeedingClass;
  
  add(date:number,idBarcodes:number[],idIngredients:number[],notes:string,alertLevel:LEVEL_CLASSNAMES,cb): FeedingClass;
  update(value:undefined|number|string,field:string,ingredient:FeedingClass,cb): FeedingClass;
  delete(ingredient:FeedingClass,cb): void;
};

/**
 * @namespace
 * @folder Lib/Services
 * @type {Object}
 */
let S_Feeding: I_S_Feeding = {
  /**
   * @memberof S_Ingredient
   * @property {string} STORAGE_KEY_INGREDIENT Clé de sauvegarde en localStorage des ingredients custom
   */
  STORAGE_KEY_FEEDING_CONTEXT : 'feedings',

  /** Liste des instances d'IngredientClass */
  feedings : [],

  /**
   * Récupère les données de l'app (csv) et en localStorage, et contruit la liste des instances d'FeedingClass
   * @param cb Fonction de callback
   */
  fetch: (cb) => {
    // if(S_App.isLogDebug) console.log('-- S_Feeding.tsx -- fetch()...');
    let forceStore = false; // on restore after, car on a recup old datas

    // reset
    S_Feeding.feedings = [];

    // A. contextes
    let feedingContexts: I_FeedingContext[] = [];

    var locSto = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Feeding.STORAGE_KEY_FEEDING_CONTEXT];
    let temporaryOldDatas = [];
    if(locSto){
      temporaryOldDatas = JSON.parse(locSto);
    }
    // si old datas => on transfo
    temporaryOldDatas.forEach(element => {
      // recup ancien format
      const elementID = element.id!==undefined ? element.id : element.idFeeding;
      if(element.id!==undefined) forceStore = true;
      const elementIngredients = !!element.ingredients ? [...element.ingredients] : [...element.idIngredients];

      feedingContexts.push({
        idFeeding: elementID,
        idContext: undefined,
        date: element.date,
        idBarcodes: !!element.idBarcodes ? [...element.idBarcodes] : [],
        idIngredients: !!elementIngredients ? elementIngredients : [],
        notes: element.notes,
        alertLevel: element.alertLevel
      });
    });
    if(S_App.isLogDebug) console.log('-- S_Feeding.tsx -- fetch() feedingContexts',feedingContexts);

    // B. creation des instances de FeedingClass
    feedingContexts.forEach(element => {
      let obj = new FeedingClass(element);
      S_Feeding.feedings.push(obj);
    });
      
    if(S_App.isLogDebug) console.log('-- S_Feeding.tsx -- fetch() feedings',S_Feeding.feedings);

    // si on a recupere les anciennes datas contexte, on re-enregistre
    if(forceStore){
      if(S_App.isLogDebug) console.log('-- S_Feeding.tsx -- fetch() datas update, store again');
      S_Feeding.store(cb);
    }else{
      if(cb)
        cb();
    }
  },
  /**
   * Enregistre les donnees en localStorage
   * @param cb Fonction de callback
   */
  store: (cb) => {
    let newFeedingContexts = [];
    if(!!S_Feeding.feedings){
      S_Feeding.feedings.forEach(element => {
        newFeedingContexts.push(element.getContextObject());
      });
    }
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Feeding.STORAGE_KEY_FEEDING_CONTEXT] = JSON.stringify(newFeedingContexts);
    if(cb)
      cb();
  },

  /**
   * Renvoi l'objet FeedingClass correspondant au parametre
   * @param idFeeding ID de la référence feeding
   * @returns {FeedingClass}
   */
  getById: (idFeeding:number|string) : FeedingClass => {
    const matchingFeedingClasses = S_Feeding.feedings.filter(element => element.idFeeding === idFeeding);
    return !!matchingFeedingClasses && matchingFeedingClasses.length>0 ? matchingFeedingClasses[0] : undefined;
  },

  /**
   * Ajoute un repas
   * @param cb Fonction callback
   * @returns {FeedingClass}
   */
  add(date:number,idBarcodes:number[],idIngredients:number[],notes:string,alertLevel:LEVEL_CLASSNAMES,cb):FeedingClass{
      
    const dateNow = (new Date()).getTime();
    const newContextObject = {
      idFeeding: dateNow,
      idContext: undefined,
      date: date,
      idBarcodes: idBarcodes,
      idIngredients: idIngredients,
      notes: notes,
      alertLevel: alertLevel
    };
    const newFeedingClass = new FeedingClass(newContextObject);

    if(!S_Feeding.feedings)
      S_Feeding.feedings = [];
      S_Feeding.feedings.push(newFeedingClass);
    
      S_Feeding.store(cb);
    return newFeedingClass;
  },
  /**
   * 
   * @param value nouvelle valeur a enregistrer pour l'attribut
   * @param field nom de l'attribut a modifier
   * @param feeding feeding à modifier, instance de FeedingClass
   * @param cb Fonction callback
   * @returns {FeedingClass}
   */
  update(value:any,field:string,feeding:FeedingClass,cb):FeedingClass{
    if(!S_Feeding.feedings || S_Feeding.feedings.length===0 || !feeding){
      if(cb) cb();
      return;
    }
    
    let matchingFeedings = S_Feeding.feedings.filter(element => element.idFeeding === feeding.idFeeding);
    if(!matchingFeedings || matchingFeedings.length === 0){
      console.log('Cannot update feeding, idFeeding',feeding.idFeeding+'not found.');
      return;
    }

    if('date'===field){
      // value doit etre en millis
      matchingFeedings[0][field] = value!==undefined ? (new Date(value)).getTime() : undefined;
    }else{
      matchingFeedings[0][field] = value;
    }
    
    S_Feeding.store(cb);
    return matchingFeedings[0];
  },
  /**
   * Supprime le repas
   * @param feeding feeding à supprimer
   * @param cb Fonction callback
   */
  delete(feeding:FeedingClass,cb){
    if(!S_Feeding.feedings || S_Feeding.feedings.length===0 || !feeding){
      if(cb) cb();
      return;
    }
    
    let matchingFeedingIndex = undefined;
    S_Feeding.feedings.forEach((element,index) => {
      if(element.idFeeding === feeding.idFeeding){
        matchingFeedingIndex = index;
      }
    });
    if(matchingFeedingIndex === -1){
      console.log('Cannot delete feeding, idFeeding',feeding.idFeeding+'not found.');
      return;
    }
    S_Feeding.feedings.splice(matchingFeedingIndex,1);

    S_Feeding.store(cb);
  },
};
export default S_Feeding;