import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import ToggleButton      from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ListAltIcon from '@mui/icons-material/ListAlt';

// Composants génériques

// Composants propres
import S_Cross      from '../lib-services/S_Cross';
import IngredientClass        from '../lib-classes/IngredientClass';
import FeedingsListByDays  from '../ui-lists/FeedingsListByDays';

// Styles
// import './DialogEditIngredient.scss';

type DialogEditIngredientProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickDelete: any;
  onClickValidate: any;

  item: IngredientClass;
  editFeeding: any;
}

function DialogEditIngredient(props:DialogEditIngredientProps) {

  // props
  let {isDialogOpened,closeDialog,onClickDelete,onClickValidate,item,editFeeding} = props;

  // state
  const [label_fr, setLabel_fr] = useState(item ? item.label_fr : 'Nouvel aliment');
  const [notes, setNotes] = useState(item ? item.notes : undefined);
  const [alertLevel, setAlertLevel] = useState(item ? item.alertLevel : '');
  const [displayFeedings, setDisplayFeedings] = useState(false);

  function changeLabel_fr(value){
    setLabel_fr(value);
  }
  function changeNotes(value){
    setNotes(value);
  }
  function changeAlertLevel(value){
    setAlertLevel(value);
  }

  let allFeedings = S_Cross.getAllFeedingsOfIngredient(item.idIngredient);

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus>
      <DialogTitle id="alert-dialog-title">Modifier un aliment</DialogTitle>
      <DialogContent>

        <div className="mv-1">
          <TextField id="label_fr" label={'Nom'} type="text" fullWidth size="small"
            value={label_fr}
            disabled={!item.isCustom()}
            onChange={(e) => changeLabel_fr(e.target.value)} />
        </div>

        <div className="text-left mv-1">
          <div>Niveau d'alerte</div>
          <ToggleButtonGroup value={alertLevel} exclusive onChange={(event, newAlignment) => changeAlertLevel(newAlignment)} aria-label="Niveau d'alerte">
            <ToggleButton value="" aria-label="Indéfini">
              <CircleOutlinedIcon />
            </ToggleButton>
            <ToggleButton value="success" aria-label="Tout va bien" color="success">
              <CheckCircleOutlineIcon />
            </ToggleButton>
            <ToggleButton value="warning" aria-label="Léger doute" color="warning">
              <HelpOutlineIcon />
            </ToggleButton>
            <ToggleButton value="danger" aria-label="Problème" color="error">
              <ErrorOutlineIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div className="mv-1">
          <TextField id="notes" label={'Observations'} type="text" fullWidth size="small"
            value={notes}
            onChange={(e) => changeNotes(e.target.value)} />
        </div>

        {!!allFeedings && allFeedings.length>0 ? (
        <div className="mt-3">
          <Button startIcon={<ListAltIcon />} variant={displayFeedings ? 'outlined' : 'contained'} onClick={(e) => setDisplayFeedings(!displayFeedings)} fullWidth>Repas concernés</Button>
          {displayFeedings ? (
          <div className="mv-1 overflow-v border">
            <FeedingsListByDays feedings={allFeedings} />
          </div>
          ) : null}
        </div>
        ) : null}

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Annuler</Button>
        {item.isCustom() ? <Button onClick={(e) => onClickDelete()} color="primary">Supprimer</Button> : null}
        <Button onClick={(e) => onClickValidate(label_fr,notes,alertLevel)} color="primary">Valider</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogEditIngredient;
