import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques

// Composants propres
import BarcodeClass      from '../lib-classes/BarcodeClass';
import MyBarcodeCard      from '../ui-primitives/MyBarcodeCard';
import IngredientAsInlineLabel          from '../ui-objects/IngredientAsInlineLabel';
import IngredientClass      from '../lib-classes/IngredientClass';
import S_Ingredient     from '../lib-services/S_Ingredient';
import S_Cross     from '../lib-services/S_Cross';

// Styles
import './BarcodeAsCard.scss';

type BarcodeAsCardProps = {
  /** Barcode */
  barcode: BarcodeClass;
  /** Callback au click */
  editBarcode?: any;
}

/**
 * Affiche une card Ingredient.
 * @param props 
 * @returns {BarcodeAsCard}
 */
function BarcodeAsCard(props:BarcodeAsCardProps) {

  // props
  let {barcode,editBarcode} = props;

  // state

  // autres variables

  if(!barcode)
    return null;
  
  const allFeedings = S_Cross.getAllFeedingsOfBarcode(barcode.idBarcode);
  const feedingsAlertsCount = S_Cross.countFeedingsWithAlert(allFeedings);

  const ageLabel = barcode.age !== undefined ? <i className="age"> (+{barcode.age}m.)</i> : null;
  const title = 
    <>
      {!!barcode.label_fr ? <>{barcode.label_fr}</> : <i>Aucun nom</i>}
      {ageLabel}
    </>
  ;
  const mediumLabel = 
    <>
      {barcode.trademark !== undefined ? <span className="trademark">{barcode.trademark}</span> : null}
      {barcode.idBarcode !== undefined ? <i className="id">{barcode.idBarcode}</i> : null}
      <div>
        <small>
          {!!allFeedings && allFeedings.length>0 ? <>{allFeedings.length} repas, {feedingsAlertsCount} alerte(s)</> : <>&nbsp;</> }
        </small>
    </div>
    </>
  ; 
  const ingredients = 
    <>
    {!!barcode.idIngredients && barcode.idIngredients.length>0 ? (
      <div className="barcodeIngredients">
        {barcode.idIngredients.map((idIngredient,index2) => {
          const ingredient : IngredientClass = S_Ingredient.getById(idIngredient);
          if(!ingredient)
            return <span key={index2} className={'barcodeIngredient'}><i>{idIngredient}</i></span>;
          return (
            <IngredientAsInlineLabel key={index2}
              ingredient={ingredient}
              className={'barcodeIngredient'} />
          );
        })}
      </div>
    ) : null}
    </>
  ;

  return (
    <MyBarcodeCard
      title={title}
      mediumLabel={mediumLabel}
      customFlag={barcode.isCustom()}
      onClick={editBarcode}>
      {ingredients}
    </MyBarcodeCard>
  );
}

export default BarcodeAsCard;
