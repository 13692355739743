import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import ToggleButton      from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ViewListIcon from '@mui/icons-material/ViewList';

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import BarcodeClass     from '../lib-classes/BarcodeClass';
import IngredientClass     from '../lib-classes/IngredientClass';

// Styles
// import './DialogAddFeeding.scss';

type DialogAddFeedingProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickValidate: any;
  scanWithCallback: any;
  chooseWithCallback: any;

  storedBarcodes: BarcodeClass[];
  storedIngredients: IngredientClass[];

  // les ingredients sont geres dans App
  defaultIngredientsID: number[];
  setFeedingToCreateIngredients: any;
  // la date est geree dans App
  feedingToCreateDefaultDate: number;
  setFeedingToCreateDefaultDate: any;
}

function DialogAddFeeding(props:DialogAddFeedingProps) {

  // props
  let {isDialogOpened,closeDialog,feedingToCreateDefaultDate,setFeedingToCreateDefaultDate,onClickValidate,scanWithCallback,chooseWithCallback,storedBarcodes,storedIngredients,defaultIngredientsID,setFeedingToCreateIngredients} = props;

  // state
  const [notes, setNotes] = useState(undefined);
  const [alertLevel, setAlertLevel] = useState('');

  const [barcodes, setBarcodes] = useState([]);
  


  function getDefaultDateInMillis(){
    return feedingToCreateDefaultDate!==undefined ? feedingToCreateDefaultDate : (new Date()).getTime();
  }

  function changeDate(value){
    // console.log('changeDate',value);
    setFeedingToCreateDefaultDate(S_Date.formatDateAndTimeInMillis(value,S_Date.formatTimeForMaterial(getDefaultDateInMillis())));
  }
  function changeHour(value){
    // console.log('changeHour',value);
    setFeedingToCreateDefaultDate(S_Date.formatDateAndTimeInMillis(S_Date.formatDateForMaterial(getDefaultDateInMillis()),value));
  }
  function changeIngredients(value){
    setFeedingToCreateIngredients(value);
  }
  function changeNotes(value){
    setNotes(value);
  }
  function changeAlertLevel(value){
    setAlertLevel(value);
  }
  function changeBarcodes(value){
    setBarcodes(value);
  }

  let storedBarcodesOrdered = S_App.sortByLabel(storedBarcodes);
  let storedIngredientsOrdered = S_App.sortByLabel(storedIngredients);

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus>
      <DialogTitle id="alert-dialog-title">Ajouter un repas</DialogTitle>
      <DialogContent>

        <div className="mv-1">
          <TextField label="Date" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth 
            value={S_Date.formatDateForMaterial(getDefaultDateInMillis())} 
            onChange={(e) => changeDate(e.target.value)} />
        </div>
        <div className="mv-1">
          <TextField label="Heure" type="time" size="small" InputLabelProps={{ shrink: true }} fullWidth 
            value={S_Date.formatTimeForMaterial(getDefaultDateInMillis())} 
            onChange={(e) => changeHour(e.target.value)} />
        </div>

        <div className="text-left mv-1">
          <div>Plats</div>
          {!!storedBarcodesOrdered && storedBarcodesOrdered.length>0 ? (
            <>
            <FormControl fullWidth>
              <Select labelId="barcodes" id="barcodes" value={barcodes} label="Plats" onChange={(e) => changeBarcodes(e.target.value as string)} multiple={true}>
                {storedBarcodesOrdered.map((barcode) => {
                  if(barcode.idBarcode===undefined)
                    return null;
                  return (
                    <MenuItem key={barcode.idBarcode} value={barcode.idBarcode}>{barcode.label_fr}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            </>
          ) : (
            <div className="text-center">
              Aucun plat enregistré.
            </div>
          )}
        </div>

        <div className="text-left mv-1">
          <div>Aliments</div>
          {!!storedIngredientsOrdered && storedIngredientsOrdered.length>0 ? (
            <>
            <FormControl fullWidth>
              <Select labelId="ingredients" id="ingredients" value={defaultIngredientsID} label="Aliments" onChange={(e) => changeIngredients(e.target.value as string)} multiple={true}>
                {storedIngredientsOrdered.map((ingredient) => {
                  return (
                    <MenuItem key={ingredient.idIngredient} value={ingredient.idIngredient}>{ingredient.label_fr}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Button variant="outlined" onClick={scanWithCallback} color="primary" startIcon={<DocumentScannerIcon />} fullWidth>Scanner un code barre</Button>
            <Button variant="outlined" onClick={chooseWithCallback} color="primary" startIcon={<ViewListIcon />} fullWidth>Choisir un code barre</Button>
            </>
          ) : (
            <div className="text-center">
              Aucun ingrédient enregistré.
            </div>
          )}
        </div>

        <div className="text-left mv-1">
          <div>Niveau d'alerte</div>
          <ToggleButtonGroup value={alertLevel} exclusive onChange={(event, newAlignment) => changeAlertLevel(newAlignment)} aria-label="Niveau d'alerte">
            <ToggleButton value="" aria-label="Indéfini">
              <CircleOutlinedIcon />
            </ToggleButton>
            <ToggleButton value="success" aria-label="Tout va bien" color="success">
              <CheckCircleOutlineIcon />
            </ToggleButton>
            <ToggleButton value="warning" aria-label="Léger doute" color="warning">
              <HelpOutlineIcon />
            </ToggleButton>
            <ToggleButton value="danger" aria-label="Problème" color="error">
              <ErrorOutlineIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div className="mv-1">
          <TextField id="notes" label={'Observations'} type="text" fullWidth size="small"
            value={notes}
            onChange={(e) => changeNotes(e.target.value)} />
        </div>

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Annuler</Button>
        <Button onClick={(e) => onClickValidate(getDefaultDateInMillis(),barcodes,defaultIngredientsID,notes,alertLevel)} color="primary">Valider</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogAddFeeding;
