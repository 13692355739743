import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

// Composants génériques

// Composants propres
import LEVEL_CLASSNAMES  from '../lib-enums/E_LevelClassNames';

// Styles
import './MyFeedingLine.scss';

type MyFeedingLineProps = {
  /** Indication de l'heure */
  time: string;
  /** Composant listant des ingredients */
  children: any;
  /** Indication du bas */
  bottomLabel?: any;
  /** Color */
  alertClassName?: LEVEL_CLASSNAMES;
  /** Classname */
  className?: string;
  /** On click function */
  onClick?: any;
}

/**
 * Affiche un repas.
 * @param props 
 * @returns {MyFeedingLine}
 */
function MyFeedingLine(props:MyFeedingLineProps) {

  // props
  let {time,children,bottomLabel,alertClassName,className,onClick} = props;

  // state

  // autres variables


  return (
    <div className={'dayFeeding '+alertClassName+' '+className} onClick={onClick}>
      <div className="dayFeedingTime"><strong><QueryBuilderIcon /><br/>{time}</strong></div>
      <div className="">
        {children}
        <div className="dayFeedingNotes"><small>{bottomLabel}</small></div>
      </div>
    </div>
  );
}

export default MyFeedingLine;
