import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques

// Composants propres
import BarcodeClass   from '../lib-classes/BarcodeClass';

// Composants primitves
import MyInlineLabel   from '../ui-primitives/MyInlineLabel';

// Styles
import './BarcodeAsInlineLabel.scss';

type BarcodeAsInlineLabelProps = {
  /** Barcode */
  barcode: BarcodeClass;
  /** Classname */
  className?: string;
}

/**
 * Affiche un label Barcode.
 * @param props 
 * @returns {BarcodeAsInlineLabel}
 */
function BarcodeAsInlineLabel(props:BarcodeAsInlineLabelProps) {

  // props
  let {barcode,className} = props;

  // state

  // autres variables

  if(!barcode)
    return null;

  const title = <>{barcode.label_fr}{!!barcode.trademark ? <i> {barcode.trademark}</i> : null}</>;

  return (
    <MyInlineLabel 
      label={title}
      className={'feedingInlineLabel '+className} />
  );
}

export default BarcodeAsInlineLabel;
