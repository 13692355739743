import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques

// Composants propres

// Styles
import './MyBarcodeCard.scss';

type MyBarcodeCardProps = {
  /** Nom */
  title: any;
  /** Indication du milieu */
  mediumLabel?: any;
  /** Indication du bas */
  bottomLabel?: any;
  /** Custom flag */
  customFlag?: boolean;
  /** Composant listant des ingredients */
  children: any;
  /** Callback au click */
  onClick?: any;
  /** ClasName additionnelle */
  className?: string;
}

/**
 * Affiche une card Ingredient.
 * @param props 
 * @returns {MyBarcodeCard}
 */
function MyBarcodeCard(props:MyBarcodeCardProps) {

  // props
  let {title,mediumLabel,bottomLabel,customFlag,children,onClick,className} = props;

  // state

  // chargement des donnees

  // autres variables


  return (
    <div className="barcode" onClick={onClick}>
      <div className={'barcodeCell '+(!!customFlag ? ' customRef' : '')+' '+className}>
        <h2>{title}</h2>
        <div><small>{mediumLabel}</small></div>
        {children}
        {!!bottomLabel ? <div><small><i>{bottomLabel}</i></small></div> : null}
      </div>
    </div>
  );
}

export default MyBarcodeCard;
