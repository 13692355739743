import React, { useState, useEffect } from 'react';

// Composants Material UI
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Snackbar from '@mui/material/Snackbar';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import HomeIcon from '@mui/icons-material/Home';

// Composants generiques
import { Panels } from 'react-mui-pwa-tools';


// Composants propres
import PanelConfig      from './metier/panels/PanelConfig';
import PanelFeedings    from './metier/panels/PanelFeedings';
import PanelIngredients from './metier/panels/PanelIngredients'
import PanelCalendar    from './metier/panels/PanelCalendar';
import PanelDashboard   from './metier/panels/PanelDashboard';
import PanelBarcodes    from './metier/panels/PanelBarcodes';
import PanelLoading     from './metier/panels/PanelLoading';

import DialogAddFeeding  from './metier/dialogs/DialogAddFeeding';
import DialogEditFeeding from './metier/dialogs/DialogEditFeeding';

import DialogAddIngredient  from './metier/dialogs/DialogAddIngredient';
import DialogEditIngredient from './metier/dialogs/DialogEditIngredient';

import DialogScan       from './metier/dialogs/DialogScan';
import DialogChoose     from './metier/dialogs/DialogChoose';
import DialogAddBarcode from './metier/dialogs/DialogAddBarcode';
import DialogEditBarcode from './metier/dialogs/DialogEditBarcode';

import S_Cross        from './metier/lib-services/S_Cross';

import S_Feeding        from './metier/lib-services/S_Feeding';
import FeedingClass     from './metier/lib-classes/FeedingClass';

import S_Ingredient     from './metier/lib-services/S_Ingredient';
import IngredientClass     from './metier/lib-classes/IngredientClass';

import S_Barcode     from './metier/lib-services/S_Barcode';
import BarcodeClass      from './metier/lib-classes/BarcodeClass';


// Styles
import './App.scss';

function App() {

  // state
  const [started, setStarted] = useState(false);

  const [swipePanelAllowed, setSwipePanelAllowed] = useState(true);

  const [feedings, setFeedings] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [barcodes, setBarcodes] = useState([]);

  const [feedingToCreateDefaultDate, setFeedingToCreateDefaultDate] = useState(undefined);
  const [feedingToCreateIngredients, setFeedingToCreateIngredients] = useState([]);
  const [feedingToEdit, setFeedingToEdit] = useState(undefined);
  const [isDialogAddFeedingOpened, setIsDialogAddFeedingOpened] = useState(false);
  const [isDialogEditFeedingOpened, setIsDialogEditFeedingOpened] = useState(false);

  const [ingredientToEdit, setIngredientToEdit] = useState(undefined);
  const [isDialogAddIngredientOpened, setIsDialogAddIngredientOpened] = useState(false);
  const [isDialogEditIngredientOpened, setIsDialogEditIngredientOpened] = useState(false);

  const [barcodeToEdit, setBarcodeToEdit] = useState(undefined);
  const [isDialogScanOpened, setIsDialogScanOpened] = useState(false);
  const [isDialogChooseOpened, setIsDialogChooseOpened] = useState(false);
  const [dialogScanAdd, setDialogScanAdd] = useState(false);
  const [dialogChooseAdd, setDialogChooseAdd] = useState(false);
  const [isDialogAddBarcodeOpened, setIsDialogAddBarcodeOpened] = useState(false);
  const [isDialogEditBarcodeOpened, setIsDialogEditBarcodeOpened] = useState(false);

  const [isSnackbarOpened, setIsSnackbarOpened] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');


  // chargement des donnees
  useEffect(() => {
    S_Ingredient.fetch(() => {
      setIngredients([...S_Ingredient.ingredients]);
      S_Barcode.fetch(() => {
        setBarcodes([...S_Barcode.barcodes]);
        S_Feeding.fetch(() => {
          setFeedings([...S_Feeding.feedings]);
          setStarted(true);
        });
      });
    });
  }, []);



  // ----------------------------

  function addFeeding(){
    setFeedingToCreateDefaultDate(undefined);
    setFeedingToCreateIngredients([]);
    setIsDialogAddFeedingOpened(true);
  }
  function addFeedingYesterday(){
    // console.log('today',(new Date()).getTime());
    let yesterday = (new Date()).getTime() - (1000*60*60*24);
    // console.log('yesterday',yesterday);
    setFeedingToCreateDefaultDate(yesterday);
    setFeedingToCreateIngredients([]);
    setIsDialogAddFeedingOpened(true);
  }
  function addFeedingValidate(dateInMillis,barcodes,ingredients,notes,alertLevel){
    S_Feeding.add(dateInMillis,barcodes,ingredients,notes,alertLevel,() => {
      setFeedings([...S_Feeding.feedings]);
      setFeedingToCreateIngredients([]);
      setIsDialogAddFeedingOpened(false);
    });
  }
  function editFeeding(feeding:FeedingClass){
    setFeedingToEdit(feeding);
    setIsDialogEditFeedingOpened(true);
  }
  function editFeedingValidate(dateInMillis,barcodes,ingredients,notes,alertLevel){
    S_Feeding.update(dateInMillis,'date',feedingToEdit,() => {
      S_Feeding.update(barcodes,'idBarcodes',feedingToEdit,() => {
        S_Feeding.update(ingredients,'idIngredients',feedingToEdit,() => {
          S_Feeding.update(notes,'notes',feedingToEdit,() => {
            S_Feeding.update(alertLevel,'alertLevel',feedingToEdit,() => {
              setFeedings([...S_Feeding.feedings]);
              setIsDialogEditFeedingOpened(false);
              setFeedingToEdit(undefined);
            });
          });
        });
      });
    });
  }
  function editFeedingDelete(){
    S_Feeding.delete(feedingToEdit,() => {
      setFeedings([...S_Feeding.feedings]);
      setIsDialogEditFeedingOpened(false);
      setFeedingToEdit(undefined);
    });
  }
  function closeEditFeeding(){
    setIsDialogEditFeedingOpened(false);
    setFeedingToCreateIngredients([]);
    setFeedingToEdit(undefined);
  }
  function addIngredientsToFeeding(ingredientsIDToAdd){
    console.log('-- addIngredientsToFeeding -- ingredientsIDToAdd',ingredientsIDToAdd);
    if(!!feedingToEdit){
      for(var i = 0; i < ingredientsIDToAdd.length; i++){
        let ingredientIDToAdd = ingredientsIDToAdd[i];
        if(feedingToEdit.ingredients.indexOf(ingredientIDToAdd)===-1)
          feedingToEdit.ingredients.push(ingredientIDToAdd);
      }
      setFeedingToEdit(feedingToEdit);
    }else{
      for(var i = 0; i < ingredientsIDToAdd.length; i++){
        let ingredientIDToAdd = ingredientsIDToAdd[i];
        if(feedingToCreateIngredients.indexOf(ingredientIDToAdd)===-1)
          feedingToCreateIngredients.push(ingredientIDToAdd);  // NB utilise directement par un select
      }
      setFeedingToCreateIngredients([...feedingToCreateIngredients]);
      console.log('-- addIngredientsToFeeding -- feedingToCreateIngredients',feedingToCreateIngredients);
    }
  }

  // ----------------------------------------------------------------------
  // ------------------------ INGREDIENTS          ------------------------
  // ----------------------------------------------------------------------

  function addIngredient(){
    setIsDialogAddIngredientOpened(true);
  }
  function addIngredientValidate(label_fr:string){
    S_Ingredient.addRef(label_fr,() => {
      setIngredients([...S_Ingredient.ingredients]);
      setIsDialogAddIngredientOpened(false);
      // TODO : scroll vers la card ?
    });
  }
  function editIngredient(ingredient:IngredientClass){
    setIngredientToEdit(ingredient);
    setIsDialogEditIngredientOpened(true);
  }
  function editIngredientValidate(label_fr:string,notes:string,alertLevel:string){
    // NB : label ne sera pas modifie si c'est une ref de l'app
    // console.log('editIngredientValidate',ingredientToEdit);
    if(ingredientToEdit.isCustom()){
      S_Ingredient.update(label_fr,'label_fr',ingredientToEdit,() => {
        S_Ingredient.update(notes,'notes',ingredientToEdit,() => {
          S_Ingredient.update(alertLevel,'alertLevel',ingredientToEdit,() => {
            setIngredients([...S_Ingredient.ingredients]);
            setIsDialogEditIngredientOpened(false);
            setIngredientToEdit(undefined);
          });
        });
      });
    }else{
      S_Ingredient.update(notes,'notes',ingredientToEdit,() => {
        S_Ingredient.update(alertLevel,'alertLevel',ingredientToEdit,() => {
          setIngredients([...S_Ingredient.ingredients]);
          setIsDialogEditIngredientOpened(false);
          setIngredientToEdit(undefined);
        });
      });
    }
  }
  function deleteIngredientValidate(){
    // recherche si on a des repas qui referencent l'ingredient
    let ingredientFeedings = S_Cross.getAllFeedingsOfIngredient(ingredientToEdit.idIngredient);
    if(!ingredientFeedings || ingredientFeedings.length===0){
      S_Ingredient.delete(ingredientToEdit,() => {
        setIngredients([...S_Ingredient.ingredients]);
        setIsDialogEditIngredientOpened(false);
        setIngredientToEdit(undefined);
      });
    }else{
      alert('Des repas référencent cet aliment.');
    }
  }
  function closeEditIngredient(){
    setIsDialogEditIngredientOpened(false);
    setIngredientToEdit(undefined);
  }


  // ----------------------------------------------------------------------
  // ------------------------ BARCODES             ------------------------
  // ----------------------------------------------------------------------

  function scan(){
    setIsDialogScanOpened(true);
    setDialogScanAdd(false);
  }
  function scanSave(label_fr:string,trademark:string,idIngredients:number[]){
    S_Barcode.addRef(label_fr,trademark,ingredients,() => {
      setBarcodes([...S_Barcode.barcodes]);
    });
  }
  function choose(){
    setIsDialogChooseOpened(true);
    setDialogChooseAdd(false);
  }
  function chooseSave(label_fr:string,trademark:string,idIngredients:number[]){
    S_Barcode.addRef(label_fr,trademark,ingredients,() => {
      setBarcodes([...S_Barcode.barcodes]);
    });
  }
  function deleteBarcode(barcode:BarcodeClass){
    S_Barcode.delete(barcode,() => {
      setBarcodes([...S_Barcode.barcodes]);
    });
  }
  function scanWithCallback(){
    setDialogScanAdd(true);
    setIsDialogScanOpened(true);
  }
  function chooseWithCallback(){
    setDialogChooseAdd(true);
    setIsDialogChooseOpened(true);
  }
  function addBarcode(){
    setIsDialogAddBarcodeOpened(true);
  }
  function addBarcodeValidate(label_fr:string,trademark:string,idIngredients:number[]){
    S_Barcode.addRef(label_fr,trademark,idIngredients,() => {
      setBarcodes([...S_Barcode.barcodes]);
      setIsDialogAddBarcodeOpened(false);
      // TODO : scroll vers la card ?
    });
  }
  function editBarcode(barcode:BarcodeClass){
    setBarcodeToEdit(barcode);
    setIsDialogEditBarcodeOpened(true);
  }
  function editBarcodeValidate(label_fr,trademark,ingredients){
    S_Barcode.update(label_fr,'label_fr',barcodeToEdit,() => {
      S_Barcode.update(trademark,'trademark',barcodeToEdit,() => {
        S_Barcode.update(ingredients,'idIngredients',barcodeToEdit,() => {
          setBarcodes([...S_Barcode.barcodes]);
          setIsDialogEditBarcodeOpened(false);
          setBarcodeToEdit(undefined);
        });
      });
    });
  }
  function deleteBarcodeValidate(){
    // recherche si on a des repas qui referencent l'ingredient
    let barcodeFeedings = S_Cross.getAllFeedingsOfBarcode(barcodeToEdit.idBarcode);
    if(!barcodeFeedings || barcodeFeedings.length===0){
      S_Barcode.delete(barcodeToEdit,() => {
        setBarcodes([...S_Barcode.barcodes]);
        setIsDialogEditBarcodeOpened(false);
        setBarcodeToEdit(undefined);
      });
    }else{
      alert('Des repas référencent ce plat.');
    }
  }
  function closeEditBarcode(){
    setIsDialogEditBarcodeOpened(false);
    setBarcodeToEdit(undefined);
  }

  // ----------------------------

  function toggleSwipePanelAllowed(){
    setSwipePanelAllowed(!swipePanelAllowed);
  }





  // https://getwaves.io/
  let PanelWaves = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#fcbe62" fill-opacity="1" d="M0,256L40,256C80,256,160,256,240,224C320,192,400,128,480,128C560,128,640,192,720,229.3C800,267,880,277,960,256C1040,235,1120,181,1200,149.3C1280,117,1360,107,1400,101.3L1440,96L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>
    </svg>
  );

  if(!started){
    const loadingPanel = [
      <PanelLoading />
    ];
    const loadingTab = [
      {
        label: 'Accueil',
        icon: <HomeIcon /> 
      }
    ];
    return (
      <Panels 
        enabled={false}
        contents={loadingPanel}
        tabs={loadingTab}
        defaultPanelIndex={0}
        tabsLabelOnSmallScreen={true}
        topContent={PanelWaves}
        size="small"/>
    );
  }












  

  let panelContents = [
    <PanelDashboard
      feedings={feedings}
      ingredients={[]}
      addFeeding={addFeeding}
      addFeedingYesterday={addFeedingYesterday}
      editFeeding={editFeeding}  />,
    <PanelFeedings 
      feedings={feedings}
      addFeeding={addFeeding}
      editFeeding={editFeeding} />,
    <PanelIngredients 
      ingredients={ingredients}
      addIngredient={addIngredient}
      editIngredient={editIngredient} />,
    <PanelBarcodes 
      barcodes={barcodes}
      addBarcode={scan}
      editBarcode={editBarcode}
      scan={scan} />,
    <PanelConfig />
  ];
  if(!!ingredients && ingredients.length>0 && !!feedings && feedings.length>0){
    panelContents = [
      <PanelDashboard
        feedings={feedings}
        ingredients={ingredients}
        addFeeding={addFeeding}
        addFeedingYesterday={addFeedingYesterday}
        editFeeding={editFeeding}  />,
      <PanelCalendar 
        ingredients={ingredients}
        feedings={feedings}
        togglePanelDrag={toggleSwipePanelAllowed} />,
      <PanelFeedings 
        feedings={feedings}
        addFeeding={addFeeding}
        editFeeding={editFeeding} />,
      <PanelIngredients 
        ingredients={ingredients}
        addIngredient={addIngredient}
        editIngredient={editIngredient} />,
      <PanelBarcodes 
        barcodes={barcodes}
        addBarcode={addBarcode}
        editBarcode={editBarcode}
        scan={scan} />,
      <PanelConfig />
    ];
  }

  const CodeBarIcon = <LineWeightIcon style={{transform:'rotate(90deg)'}} />

  // On affiche le panel semainier que si on a des valeurs a afficher
  let tabs = [
    {
      label: 'Accueil',
      icon: <HomeIcon /> 
    },
    {
      label: 'Repas',
      icon: <DinnerDiningIcon /> 
    },
    {
      label: 'Aliments',
      icon: <FactCheckIcon /> 
    },
    {
      label: 'Codes',
      icon: CodeBarIcon
    },
    {
      label: 'Config',
      icon: <MoreHorizIcon /> 
    }
  ]; 
  if(!!ingredients && ingredients.length>0 && !!feedings && feedings.length>0){
    tabs = [
      {
        label: 'Accueil',
        icon: <HomeIcon /> 
      },
      {
        label: 'Semainier',
        icon: <CalendarViewWeekIcon /> 
      },
      {
        label: 'Repas',
        icon: <DinnerDiningIcon /> 
      },
      {
        label: 'Aliments',
        icon: <FactCheckIcon /> 
      },
      {
        label: 'Plats',
        icon: CodeBarIcon 
      },
      {
        label: 'Config',
        icon: <MoreHorizIcon /> 
      }
    ]; 
  }
    

  return (
    <>
    <Panels 
      enabled={swipePanelAllowed}
      contents={panelContents}
      tabs={tabs}
      defaultPanelIndex={0}
      tabsLabelOnSmallScreen={true}
      topContent={PanelWaves}
      size="small"/>

    <DialogAddFeeding
      isDialogOpened={isDialogAddFeedingOpened}
      closeDialog={(e) => setIsDialogAddFeedingOpened(false)}
      onClickValidate={addFeedingValidate}
      scanWithCallback={scanWithCallback}
      chooseWithCallback={chooseWithCallback}
      storedBarcodes={barcodes}
      storedIngredients={ingredients}
      defaultIngredientsID={feedingToCreateIngredients}
      setFeedingToCreateIngredients={setFeedingToCreateIngredients}
      feedingToCreateDefaultDate={feedingToCreateDefaultDate}
      setFeedingToCreateDefaultDate={setFeedingToCreateDefaultDate} />
    {feedingToEdit ? (
    <DialogEditFeeding
      isDialogOpened={isDialogEditFeedingOpened}
      closeDialog={closeEditFeeding}
      onClickDelete={editFeedingDelete}
      onClickValidate={editFeedingValidate}
      scanWithCallback={scanWithCallback}
      chooseWithCallback={chooseWithCallback}
      item={feedingToEdit}
      storedBarcodes={barcodes}
      storedIngredients={ingredients} />
    ):null}

    <DialogAddIngredient
      isDialogOpened={isDialogAddIngredientOpened}
      closeDialog={(e) => setIsDialogAddIngredientOpened(false)}
      onClickValidate={addIngredientValidate} />
    {ingredientToEdit ? (
    <DialogEditIngredient
      isDialogOpened={isDialogEditIngredientOpened}
      closeDialog={closeEditIngredient}
      onClickDelete={deleteIngredientValidate}
      onClickValidate={editIngredientValidate}
      item={ingredientToEdit}
      editFeeding={editFeedingValidate} />
    ):null}

    <DialogScan
      isDialogOpened={isDialogScanOpened}
      closeDialog={(e) => setIsDialogScanOpened(false)}
      onClickSave={scanSave}
      onDeleteBarcode={deleteBarcode}
      dialogScanAdd={dialogScanAdd}
      addIngredientsToFeeding={addIngredientsToFeeding}
      storedIngredients={ingredients}
      editBarcode={editBarcode} />
    <DialogChoose
      isDialogOpened={isDialogChooseOpened}
      closeDialog={(e) => setIsDialogChooseOpened(false)}
      onClickSave={chooseSave}
      dialogChooseAdd={dialogChooseAdd}
      addIngredientsToFeeding={addIngredientsToFeeding}
      editBarcode={editBarcode}
      barcodes={barcodes} />
    <DialogAddBarcode
      isDialogOpened={isDialogAddBarcodeOpened}
      closeDialog={(e) => setIsDialogAddBarcodeOpened(false)}
      onClickValidate={addBarcodeValidate}
      storedIngredients={ingredients} />
    {barcodeToEdit ? (
    <DialogEditBarcode
      isDialogOpened={isDialogEditBarcodeOpened}
      closeDialog={closeEditBarcode}
      onClickDelete={deleteBarcodeValidate}
      onClickValidate={editBarcodeValidate}
      item={barcodeToEdit}
      storedIngredients={ingredients} />
    ):null}

    <Snackbar
      open={isSnackbarOpened}
      autoHideDuration={1000}
      onClose={() => setIsSnackbarOpened(false)}
      message={snackbarMessage} />
    </>
  );
}

export default App;
