import {I_IngredientRef, I_IngredientContext}    from '../lib-interfaces/I_Ingredient';

/**
 * Represente un ingredient.
 * 
 * 
 * @folder Lib/Classes
 */
class IngredientClass {

  /** 
   * Identifiant de la référence 
   * @type number
   */   
  idIngredient: number;
  /** 
   * Libellé de la référence en francais 
   * @type string
   */
  label_fr?: string;
  /** 
   * Age minimal recommandé
   * @type number
   */
  age?: number;
  /** 
   * Identifiant du contexte 
   * @type number
   */
  idContext?: number;
  /** Observations sur cet ingredient dans le contexte */
  notes?: string;
  /** Niveau d'alerte sur cet ingredient dans le contexte */
  alertLevel?: undefined | 'success' | 'warning' | 'danger';

  /** Indique si la reference provient de l'app ou entree manuellement */
  private _isCustom: boolean =false;        // false si c'est une data en dur, true si c'est une data entree par l'utilisateur (et donc en localStorage)

  /**
   * Constructeur
   * @param {I_IngredientRef} refObject
   * @param {boolean} isCustom
   * @param {I_IngredientContext} [contextObject]
   */
  constructor(refObject:I_IngredientRef, isCustom:boolean, contextObject?:I_IngredientContext) {
    if(!refObject){
      throw new Error('Cannot instanciate IngredientClass with undefined refObject');
    }
    this.idIngredient = refObject.idIngredient;
    this.label_fr = refObject.label_fr;
    this.age = refObject.age;
    this._isCustom = isCustom;
    if(!!contextObject){
      // on verifie quand meme qu'on a le meme id ingredient
      if(contextObject.idIngredient !== this.idIngredient){
        console.error('Cannot import context object on ingredient, ingredient IDs don\'t match.',refObject,contextObject);
      }
      else{
        this.idContext = contextObject.idContext;
        this.notes = contextObject.notes;
        this.alertLevel = contextObject.alertLevel;
      }
    }
  }

  /**
   * 
   * @returns {object} Renvoi l'object json de donnees de reference
   */
  getRefObject() {
    return {
      idIngredient: this.idIngredient,
      label_fr: this.label_fr,
      age: this.age
    };
  }

  /**
   * 
   * @returns {boolean} Renvoi true si c'est un ingredient entre par l'utilisateur (et donc en localStorage), false si c'est un ingredient en dur dans l'app
   */
  isCustom():boolean {
    return this._isCustom;
  }

  /**
   * 
   * @returns Renvoi l'object json du context lie a l'ingredient, tel qu'il est enregistre en localStorage
   */
  getContextObject() {
    return {
      idContext: this.idContext,
      idIngredient: this.idIngredient,
      notes: this.notes,
      alertLevel: this.alertLevel
    };
  }

  toJson() {
    return {
      idIngredient: this.idIngredient,
      label_fr: this.label_fr,
      age: this.age,
      idContext: this.idContext,
      notes: this.notes,
      alertLevel: this.alertLevel,
      _isCustom: this._isCustom
    };
  }


  
}

export default IngredientClass;
