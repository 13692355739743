import S_App   from   './S_App';
let S_Date = {
  isLogDebug: process.env.REACT_APP_ENV !== 'production',

  sortByDate(array){
    let arrayOrdered = [].concat(array).sort((a, b) => {
      let orderA = a.date !== undefined ? a.date : a.id;
      let orderB = b.date !== undefined ? b.date : b.id;
      return orderB > orderA ? -1 : 1;
    });
    return arrayOrdered;
  },
  groupByDate(array){
    let byDate = [];
    try{
      let arraySorted = S_Date.sortByDate(array).reverse();
      byDate = S_App.groupByKey(arraySorted, 'date');
    }catch(err){
      console.error(err);
    }
    return byDate;
  },
  groupByDay(array){
    let byDate = [];
    try{
      let arraySorted = S_Date.sortByDate(array).reverse();
      byDate = S_Date._groupByKeyDay(arraySorted, 'date');

      let undefinedArray = [];
      for(var i = 0; i < array.length; i++){
        if(array[i].date===undefined)
          undefinedArray.push(array[i]);
      }
      if(undefinedArray.length>0){
        byDate[-1] = undefinedArray;
      }
    }catch(err){
      console.error(err);
    }
    return byDate;
  },

  _groupByKeyDay(array, key) {
    return array.reduce((hash, obj) => {
       if(obj[key] === undefined) return hash; 
       let dateInMillis = obj[key];
       let dayInMillis = S_Date.getDayReference(dateInMillis);
       return Object.assign(hash, { [dayInMillis]:( hash[dayInMillis] || [] ).concat(obj)})
    }, {})
  },

  // MATERIAL FIELDS --------------
  formatDateForMaterial(dateInMillis){
    let date = undefined;
    if(dateInMillis){
      let theDate = new Date(dateInMillis);
      let month = theDate.getMonth()+1;
      let day = theDate.getDate();
      date = theDate.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    }
    return date;
  },
  formatTimeForMaterial(dateInMillis:number){
    let date = undefined;
    if(dateInMillis){
      let theDate = new Date(dateInMillis);
      let hour = theDate.getHours();
      let minute = theDate.getMinutes();
      date = (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute;
    }
    return date;
  },

  // STRINGS --------------------
  formatDateInString(dateInMillis):string{
    if(dateInMillis)
      return (new Date(dateInMillis)).toLocaleDateString("fr-FR", { year: undefined, month: 'short', day: 'numeric', weekday: undefined });
    return null;
  },
  formatTimeInString(dateInMillis):string{
    if(dateInMillis)
      return (new Date(dateInMillis)).toLocaleTimeString("fr-FR", { hour12: false, hour: '2-digit', minute:'2-digit' });
    return null;
  },
  formatDateInStringWithName(dateInMillis):string{
    if(dateInMillis)
      return (new Date(dateInMillis)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' });
    return null;
  },
  formatDateInStringWithNameNoYear(dateInMillis):string{
    if(dateInMillis)
      return (new Date(dateInMillis)).toLocaleDateString("fr-FR", { year: undefined, month: 'short', day: 'numeric', weekday: undefined });
    return null;
  },

  formatDelayForNow(dateInMillis, reverse):number{
    if(!dateInMillis)
      return null;
    let ecart = dateInMillis - (new Date()).getTime();
    if(reverse)
      ecart = (new Date()).getTime() - dateInMillis;
    let days = ecart / 1000 / 60 / 60 / 24;
    return Math.round(days);
  },

  formatDateInMillis(dateInMaterial){
    if(!dateInMaterial)
      return undefined;
    // let year = parseInt(dateInMaterial.substr(0,4));
    // let month = parseInt(dateInMaterial.substr(6,8));
    // let day = parseInt(dateInMaterial.substr(10,12));
    return (new Date(dateInMaterial)).getTime();
  },
  formatDateAndTimeInMillis(dayInMaterial,timeInMaterial){
    if(!dayInMaterial)
      return undefined;

    // if(S_Date.isLogDebug) console.log('formatDateAndTimeInMillis() ',dayInMaterial,timeInMaterial);
    let hours = 1;
    let minutes = 0;
    if(!!timeInMaterial){
      hours = timeInMaterial.split(':')[0];
      minutes = timeInMaterial.split(':')[1];
    }

    let dateDate = new Date(dayInMaterial);
    dateDate = new Date(dateDate.setHours(hours,minutes,0,0));
    // if(S_Date.isLogDebug) console.log('formatDateAndTimeInMillis() ',dateDate);
    let dateMillis = dateDate.getTime();

    return dateMillis;
  },

  formatDelayFrom(days:number):string{
    if(days===undefined)
      return;
    if(days>365){
      let nombreDAn = Math.round(days / 365);
      return 'Depuis + de '+nombreDAn+' ans';
    }
    if(days>30){
      let nombreDeMois = Math.round(days / 30);
      return 'Depuis + de '+nombreDeMois+' mois';
    }
    let result = '';
    if(days<=30){
      result = 'Depuis '+days+' jours';
    }
    if(days===1){
      result = 'Depuis hier';
    }
    if(days===0){
      result = "Depuis aujourd'hui";
    }
    if(days<0){
      result = "Incohérent";
    }
    return result;
  },

  
  getCurrentDayReference(){
    let currentDayDate = new Date();
    currentDayDate = new Date(currentDayDate.setHours(1,0,0,0));  // fixe la date du jour a 1h du matin
    let currentDayMillis = currentDayDate.getTime();
    // console.log('getCurrentDayReference',currentDayMillis);
    return currentDayMillis;
  },
  getDayReference(dateInMillis){
    let dayDate = new Date(dateInMillis);
    dayDate = new Date(dayDate.setHours(1,0,0,0));  // fixe la date du jour a 1h du matin
    let dayMillis = dayDate.getTime();
    // console.log('getDayReference',dayMillis);
    return dayMillis;
  }
    
  
};
export default S_Date;