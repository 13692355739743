import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ListAltIcon from '@mui/icons-material/ListAlt';

// Composants génériques
import S_App      from '../../services/S_App';

// Composants propres
import S_Cross      from '../lib-services/S_Cross';
import BarcodeClass    from '../lib-classes/BarcodeClass';
import IngredientClass from '../lib-classes/IngredientClass';
import FeedingsListByDays  from '../ui-lists/FeedingsListByDays';

// Styles
// import './DialogEditBarcode.scss';

type DialogEditBarcodeProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickDelete: any;
  onClickValidate: any;

  item: BarcodeClass;
  storedIngredients: IngredientClass[];
}

function DialogEditBarcode(props:DialogEditBarcodeProps) {

  // props
  let {isDialogOpened,closeDialog,onClickDelete,onClickValidate,item,storedIngredients} = props;

  // state
  const [label_fr, setLabel_fr] = useState(item ? item.label_fr : 'Nouveau code barre');
  const [trademark, setTrademark] = useState(item ? item.trademark : '');
  const [ingredients, setIngredients] = useState(item ? item.idIngredients : []);
  const [displayFeedings, setDisplayFeedings] = useState(false);

  function changeLabel_fr(value){
    setLabel_fr(value);
  }
  function changeTrademark(value){
    setTrademark(value);
  }
  function changeIngredients(value){
    setIngredients(value);
  }


  let storedIngredientsOrdered = S_App.sortByLabel(storedIngredients);
  
  let allFeedings = S_Cross.getAllFeedingsOfBarcode(item.idBarcode);

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus>
      <DialogTitle id="alert-dialog-title">Modifier un plat</DialogTitle>
      <DialogContent>

        <div className="mv-1">
          <TextField id="label_fr" label={'Nom'} type="text" fullWidth size="small"
            value={label_fr}
            disabled={!item.isCustom()}
            onChange={(e) => changeLabel_fr(e.target.value)} />
        </div>

        <div className="mv-1">
          <TextField id="trademark" label={'Marque'} type="text" fullWidth size="small"
            value={trademark}
            disabled={!item.isCustom()}
            onChange={(e) => changeTrademark(e.target.value)} />
        </div>

        <div>Sélectionner les ingrédients</div>
        {!!storedIngredientsOrdered && storedIngredientsOrdered.length>0 ? (
          <>
          <FormControl fullWidth>
            <Select disabled={!item.isCustom()} labelId="ingredients" id="ingredients" value={ingredients} label="Aliments" onChange={(e) => changeIngredients(e.target.value as string)} multiple={true}>
              {storedIngredientsOrdered.map((ingredient) => {
                if(ingredient.idIngredient===undefined)
                  return null;
                return (
                  <MenuItem key={ingredient.idIngredient} value={ingredient.idIngredient}>{ingredient.label_fr}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          </>
        ) : (
          <div className="text-center">
            Aucun ingrédient enregistré.
          </div>
        )}

        {!!allFeedings && allFeedings.length>0 ? (
        <div className="mt-3">
          <Button startIcon={<ListAltIcon />} variant={displayFeedings ? 'outlined' : 'contained'} onClick={(e) => setDisplayFeedings(!displayFeedings)} fullWidth>Repas concernés</Button>
          {displayFeedings ? (
          <div className="mv-1 overflow-v border">
            <FeedingsListByDays feedings={allFeedings} />
          </div>
          ) : null}
        </div>
        ) : null}

      </DialogContent>
      <DialogActions>
        {item.isCustom() ? (
          <>
          <Button onClick={closeDialog} color="primary">Annuler</Button>
          <Button onClick={(e) => onClickDelete()} color="primary">Supprimer</Button>
          <Button onClick={(e) => onClickValidate(label_fr,trademark,ingredients)} color="primary">Valider</Button>
          </>
        ) : (
          <Button onClick={closeDialog} color="primary">Fermer</Button>
        )}
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogEditBarcode;
