import React,{useState,useEffect} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddIcon   from '@mui/icons-material/Add';

// Composants génériques

// Composants propres
import S_Ingredient       from '../lib-services/S_Ingredient';
import {I_Barcode}        from '../lib-interfaces/I_Barcode';
import BarcodeAsCard            from '../ui-objects/BarcodeAsCard';

// Styles
// import './DialogChoose.scss';

type DialogChooseProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickSave: any;
  dialogChooseAdd: boolean;
  addIngredientsToFeeding: any;
  editBarcode: any;
  barcodes: I_Barcode[];
}

function DialogChoose(props:DialogChooseProps) {

  // props
  let {isDialogOpened,closeDialog,onClickSave,dialogChooseAdd,addIngredientsToFeeding,editBarcode,barcodes} = props;

  // state
  const [request, setRequest] = useState('');
  const [barcodeObjects, setBarcodeObjects] = useState([]);
  const [verified, setVerified] = useState(false);

  // chargement des donnees
  useEffect(() => {
    setRequest('');
    setBarcodeObjects([]);
    setVerified(false);
    
  }, []);


  function changeRequest(value){
    setRequest(value);
    setBarcodeObjects([]);
    setVerified(false);
  }

  function close(){
    setRequest('');
    setBarcodeObjects([]);
    setVerified(false);
    closeDialog();
  }

  function addIngredients(ingredientsID){
    addIngredientsToFeeding(ingredientsID);

    setRequest('');
    setBarcodeObjects([]);
    setVerified(false);
    closeDialog();
  }

  function search(){
    if(!request){
      setBarcodeObjects([]);
      setVerified(true);
    }else{
      if(barcodes.length===0){
        setBarcodeObjects([]);
        setVerified(true);
      }else{
        // on filtre les barcode qui matchent le nom ou les ingredients
        let filtered = [];
        for(var i = 0; i < barcodes.length; i++){
          let barcode = barcodes[i];

          if(!!barcode.label_fr && barcode.label_fr.toLowerCase().indexOf(request.toLowerCase())!==-1){
            filtered.push(barcode);
            continue;
          }
          if(!!barcode.idIngredients){
            let found = false;
            for(var j = 0; j < barcode.idIngredients.length; j++){
              let ingredientID = barcode.idIngredients[j];
              let ingredient = S_Ingredient.getById(ingredientID);
              if(!!ingredient && ingredient.label_fr.toLowerCase().indexOf(request.toLowerCase())!==-1){
                found = true;
                break;
              }
            }
            if(found){
              filtered.push(barcode);
              continue;
            } 
          }
        }

        setBarcodeObjects(filtered);
        setVerified(true);
      }
    }
  }


  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus>
      <DialogTitle id="alert-dialog-title">Trouver un code barre</DialogTitle>
      <DialogContent>

        <div className="mv-1">
          <TextField id="request" label={'Rechercher'} type="text" fullWidth size="small"
            value={request}
            onChange={(e) => changeRequest(e.target.value)} />
        </div>

        {!!request ? (
          <div className="text-center">
            {!verified ? (
              <Button variant="outlined" onClick={search} color="primary">Vérifier</Button>
            ) : (
              <div>
                {!!barcodeObjects && barcodeObjects.length>0 ? (
                  <h2 className="success"><CheckIcon /> Trouvé(s) !</h2>
                ) : (
                  <h2 className="danger"><ErrorOutlineIcon /> Non trouvé.</h2>
                )}
              </div>
            )}
          </div>
        ) :null}
        

        {verified ? (
          <>

            {!!barcodeObjects ? (
            <>
            {barcodeObjects.map((barcode,index) => {
              return (
                <div key={index} className="mv-2">
                  <BarcodeAsCard
                    barcode={barcode}
                    editBarcode={barcode.isCustom() ? () => editBarcode(barcode) : undefined} />
                  {dialogChooseAdd ? (
                    <div className="mv-1"><Button variant="outlined" onClick={() => addIngredients(barcode.ingredients)} color="primary" startIcon={<AddIcon />} fullWidth>Ajouter au repas</Button></div>
                  ) : null}
                </div>
              );
            })}
            </>
            ) : null}

          </>
        ) : null}

      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">Fermer</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogChoose;
