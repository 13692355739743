import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

import AddIcon   from '@mui/icons-material/Add';


// Composants génériques
import S_Date     from '../../services/S_Date';

// Composants propres
import FeedingClass         from '../lib-classes/FeedingClass';
import IngredientClass     from '../lib-classes/IngredientClass';
import IngredientsSummary     from '../ui-lists/IngredientsSummary';
import FeedingsListOfADay         from '../ui-lists/FeedingsListOfADay';

// Styles
import './PanelDashboard.scss';

type PanelDashboardProps = {
  feedings: FeedingClass[];
  ingredients: IngredientClass[];
  addFeeding: any;
  addFeedingYesterday: any;
  editFeeding: any;
}

function PanelDashboard(props:PanelDashboardProps) {

  // props
  let {feedings,ingredients,addFeeding,addFeedingYesterday,editFeeding} = props;

  // state

  // chargement des donnees

  // autres variables
  let NOW = S_Date.getCurrentDayReference();
  let YESTERDAY = NOW - (1000*60*60*24);
  let feedingsOrdered = S_Date.sortByDate(feedings).reverse();
  let feedingsOrderedAndGroupedByDay = S_Date.groupByDay(feedingsOrdered);

  let todayFeedings = feedingsOrderedAndGroupedByDay[NOW];
  let yesterdayFeedings = feedingsOrderedAndGroupedByDay[YESTERDAY];

  // console.log('feedingsOrdered',feedingsOrdered);
  // console.log('feedingsOrderedAndGroupedByDay',feedingsOrderedAndGroupedByDay);
  // console.log('todayFeedings',todayFeedings);


  


  return (
    <Container fixed className="panel-contents pb-6">

      <div className="mt-vague">
        
        <div className="mv-2">
          <h2 className="primary">Aujourd'hui</h2>

          <div className="mv-2">
            {!!todayFeedings && todayFeedings.length>0 ? (
            <FeedingsListOfADay
              dayInMillis={NOW}
              feedings={todayFeedings}
              editFeeding={editFeeding} />
            ) : 'Aucun repas'}
          </div>

          <div className="text-center mv-2">
            <Button variant="outlined" onClick={addFeeding} color="primary" startIcon={<AddIcon />}>Ajouter un repas aujourd'hui</Button>
          </div>

        </div>

        <div className="mv-2">
          <h2 className="primary">Hier</h2>

          <div className="mv-2">
            {!!yesterdayFeedings && yesterdayFeedings.length>0 ? (
            <FeedingsListOfADay
              dayInMillis={YESTERDAY}
              feedings={yesterdayFeedings}
              editFeeding={editFeeding} />
            ) : 'Aucun repas'}
          </div>

          <div className="text-center mv-2">
            <Button variant="outlined" onClick={addFeedingYesterday} color="primary" startIcon={<AddIcon />}>Ajouter un repas hier</Button>
          </div>
        </div>

        {ingredients.length>0 ? (
        <div className="mv-2">
          <h2 className="primary">Récap</h2>

          <IngredientsSummary ingredients={ingredients} />
        </div>
        ) : null}

      </div>

    </Container>
  );
}

export default PanelDashboard;
