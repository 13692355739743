import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@mui/material/Container';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

// Composants génériques

// Composants propres

// Styles
// import './PanelLoading.scss';

type PanelLoadingProps = {
  
}

function PanelLoading(props:PanelLoadingProps) {

  // props
  let {} = props;

  // state

  // autres variables

  return (
    <Container fixed className="panel-contents pb-6">

      <div className="mt-vague text-center">
        <h1><HourglassTopIcon className={'maincolor'}/></h1>
      </div>

    </Container>
  );
}

export default PanelLoading;
