
import S_App      from '../../services/S_App';
import S_Date      from '../../services/S_Date';
import S_Feeding         from './S_Feeding';
import IngredientClass   from '../lib-classes/IngredientClass';
import LEVEL_CLASSNAMES  from '../lib-enums/E_LevelClassNames';
import FeedingClass from '../lib-classes/FeedingClass';

interface I_S_Cross {
  getIngredientAlertClass(ingredient:IngredientClass): LEVEL_CLASSNAMES;
  getIngredientsGroupedByAlertLevel(ingredients:IngredientClass[]): I_Ingredients_GroupedBy_AlertLevels;
  generateShareIngredientsListsText(ingredientsGroupedByAlertLevel:I_Ingredients_GroupedBy_AlertLevels): string;

  getLastFeedingOfIngredient(idIngredient:number):FeedingClass;
  getFirstFeedingOfIngredient(idIngredient:number):FeedingClass;
  getAllFeedingsOfIngredient(idIngredient:number):FeedingClass[];
  getAllFeedingsOfBarcode(idBarcode:number):FeedingClass[];

  countAlertsOfFeedingWithIngredient(feedings:FeedingClass[],idIngredient:number):number;
  getFeedingsBetween(feedings:FeedingClass[],dateStart:number,dateEnd:number):FeedingClass[];
  countFeedingsWithAlert(feedings:FeedingClass[]):number;
};

interface I_Ingredients_GroupedBy_AlertLevels {
  ingredientsOK:IngredientClass[];
  ingredientsKO:IngredientClass[];
  ingredientsMaybeOK:IngredientClass[];
  ingredientsMaybeKO:IngredientClass[];
};

/**
 * Services proposant des recherches, filtrages, et rendus croisés
 * @namespace
 * @folder Lib/Services
 * @type {Object}
 */
let S_Cross: I_S_Cross = {

  /**
   * 
   * @param {IngredientClass} ingredient 
   * @returns {string} ClassName représentant le niveau d'alerte. Une valeur de LEVEL_CLASSNAMES.
   */
  getIngredientAlertClass: (ingredient:IngredientClass) : LEVEL_CLASSNAMES => {
    let result:LEVEL_CLASSNAMES = LEVEL_CLASSNAMES.UNDEFINED;

    let allFeedings = S_Cross.getAllFeedingsOfIngredient(ingredient.idIngredient);
    if(allFeedings && allFeedings.length>0){
      // nombre de repas ayant cet ingredient, qui sont en warn ou danger
      let feedingsAlertsCount = 0;

      for(var j = 0; j < allFeedings.length; j++){
        let feeding = allFeedings[j];
        if(feeding.alertLevel==='warning' || feeding.alertLevel==='danger'){
          feedingsAlertsCount++;
        }
      }

      if(feedingsAlertsCount>0){
        result = LEVEL_CLASSNAMES.LIGHTWARNING;
      }else{
        result = LEVEL_CLASSNAMES.LIGHTSUCCESS;
      }
    }

    if(ingredient.alertLevel === 'success') result = LEVEL_CLASSNAMES.SUCCESS;
    if(ingredient.alertLevel === 'warning') result = LEVEL_CLASSNAMES.WARNING;
    if(ingredient.alertLevel === 'danger') result = LEVEL_CLASSNAMES.DANGER;
    return result;
  },

  /**
   * Tri par ordre alphanum et groupe les ingredients par niveau d'alerte
   * @param {IngredientClass[]} ingredients 
   * @returns {I_Ingredients_GroupedBy_AlertLevels}
   */
  getIngredientsGroupedByAlertLevel: (ingredients:IngredientClass[]) : I_Ingredients_GroupedBy_AlertLevels => {
    let ingredientsOrdered = S_App.sortByLabel(ingredients);

    let ingredientsOK = [];
    let ingredientsKO = [];
    let ingredientsMaybeOK = [];
    let ingredientsMaybeKO = [];
    for(var i = 0; i < ingredientsOrdered.length; i++){
      let ingredient = ingredientsOrdered[i];

      if(!ingredient)
        continue;

      let alertLevel = ingredient.alertLevel;
      if(alertLevel === 'success'){
        ingredientsOK.push(ingredient);
        continue;
      }
      if(alertLevel === 'danger' || alertLevel === 'warning'){
        ingredientsKO.push(ingredient);
        continue;
      }

      let allFeedings = S_Cross.getAllFeedingsOfIngredient(ingredient.id);
      // console.log('allFeedings of',ingredient.label_fr,allFeedings);
      if(allFeedings && allFeedings.length>0){
        // nombre de repas ayant cet ingredient, qui sont en warn ou danger
        let feedingsAlertsCount = 0;

        for(var j = 0; j < allFeedings.length; j++){
          let feeding = allFeedings[j];
          if(feeding.alertLevel==='warning' || feeding.alertLevel==='danger'){
            feedingsAlertsCount++;
          }
        }

        if(feedingsAlertsCount>0){
          ingredientsMaybeKO.push(ingredient);
        }else{
          ingredientsMaybeOK.push(ingredient);
        }
      }
    }
    return {
      ingredientsOK: ingredientsOK,
      ingredientsKO: ingredientsKO,
      ingredientsMaybeOK: ingredientsMaybeOK,
      ingredientsMaybeKO: ingredientsMaybeKO
    };
  },

  /**
   * Génère le texte de partage des listes d'ingrédients groupés par niveau d'alerte.
   * @param {I_Ingredients_GroupedBy_AlertLevels} ingredientsGroupedByAlertLevel 
   * @returns {string}
   */
  generateShareIngredientsListsText: (ingredientsGroupedByAlertLevel:I_Ingredients_GroupedBy_AlertLevels) : string => {
    
    if(!ingredientsGroupedByAlertLevel)
      return 'Aucune donnée';

    const ingredientsOK = ingredientsGroupedByAlertLevel.ingredientsOK;
    const ingredientsKO = ingredientsGroupedByAlertLevel.ingredientsKO;
    const ingredientsMaybeOK = ingredientsGroupedByAlertLevel.ingredientsMaybeOK;
    const ingredientsMaybeKO = ingredientsGroupedByAlertLevel.ingredientsMaybeKO;

    let contents = '';

    // OK
    contents += 'OK : ';
    if(ingredientsOK.length<=0){
      contents += 'Aucune donnée';
    }else{
      for(var i = 0; i < ingredientsOK.length; i++){
        let ingredient = ingredientsOK[i];
        contents += ' ' + ingredient.label_fr;
      }
    }
    contents += '\n';

    // OK?
    contents += 'A priori OK : ';
    if(ingredientsMaybeOK.length>0){
      for(var i = 0; i < ingredientsMaybeOK.length; i++){
        let ingredient = ingredientsMaybeOK[i];
        contents += ' ' + ingredient.label_fr;
      }
    }
    contents += '\n';

    // KO?
    contents += 'A surveiller : ';
    if(ingredientsMaybeKO.length>0){
      for(var i = 0; i < ingredientsMaybeKO.length; i++){
        let ingredient = ingredientsMaybeKO[i];
        contents += ' ' + ingredient.label_fr;
      }
    }
    contents += '\n';

    // KO
    contents += 'KO Ne pas donner : ';
    if(ingredientsKO.length>0){
      for(var i = 0; i < ingredientsKO.length; i++){
        let ingredient = ingredientsKO[i];
        contents += ' ' + ingredient.label_fr;
      }
    }
    contents += '\n';

    return contents;
  },




  getLastFeedingOfIngredient(idIngredient:number):FeedingClass{
    if(!S_Feeding.feedings || S_Feeding.feedings.length===0 || idIngredient===undefined)
      return;

    let feedingsSortedByDateReverse = S_Date.sortByDate(S_Feeding.feedings).reverse();

    let foundData = undefined;
    for(var i = 0; i < feedingsSortedByDateReverse.length; i++){
      let feeding = feedingsSortedByDateReverse[i];
      if(feeding.idIngredients.indexOf(idIngredient)===-1)
        continue;
      foundData = feeding;
      break;
    }
    return foundData;
  },
  getFirstFeedingOfIngredient(idIngredient:number):FeedingClass{
    if(!S_Feeding.feedings || S_Feeding.feedings.length===0 || idIngredient===undefined)
      return;

    let feedingsSortedByDate = S_Date.sortByDate(S_Feeding.feedings);

    let foundData = undefined;
    for(var i = 0; i < feedingsSortedByDate.length; i++){
      let feeding = feedingsSortedByDate[i];
      if(feeding.idIngredients.indexOf(idIngredient)===-1)
        continue;
      foundData = feeding;
      break;
    }
    return foundData;
  },
  getAllFeedingsOfIngredient(idIngredient:number):FeedingClass[]{
    if(!S_Feeding.feedings || S_Feeding.feedings.length===0 || idIngredient===undefined)
      return [];

    let feedingsSortedByDateReverse = S_Date.sortByDate(S_Feeding.feedings).reverse();
    // console.log('getAllFeedingsOfIngredient() feedingsSortedByDateReverse',feedingsSortedByDateReverse);

    let foundDatas = [];
    for(var i = 0; i < feedingsSortedByDateReverse.length; i++){
      let feeding = feedingsSortedByDateReverse[i];
      if(feeding.idIngredients.indexOf(idIngredient)===-1)
        continue;
      foundDatas.push(feeding);
    }
    // console.log('getAllFeedingsOfIngredient() ingredientID',ingredientID,'foundDatas',foundDatas);
    return foundDatas;
  },
  getAllFeedingsOfBarcode(idBarcode:number):FeedingClass[]{
    if(!S_Feeding.feedings || S_Feeding.feedings.length===0 || idBarcode===undefined)
      return [];

    let feedingsSortedByDateReverse = S_Date.sortByDate(S_Feeding.feedings).reverse();

    let foundDatas = [];
    for(var i = 0; i < feedingsSortedByDateReverse.length; i++){
      let feeding = feedingsSortedByDateReverse[i];
      if(feeding.idBarcodes.indexOf(idBarcode)===-1)
        continue;
      foundDatas.push(feeding);
    }
    return foundDatas;
  },

  countAlertsOfFeedingWithIngredient(feedings:FeedingClass[],idIngredient:number):number{
    // nombre de repas ayant cet ingredient, qui sont en warn ou danger
    let feedingsAlertsCount = 0;
    if(feedings && feedings.length>0){
      for(var i = 0; i < feedings.length; i++){
        let feeding = feedings[i];
        if(feeding.alertLevel==='warning' || feeding.alertLevel==='danger'){
          feedingsAlertsCount++;
        }
      }
    }
    return feedingsAlertsCount;
  },
  getFeedingsBetween(feedings:FeedingClass[],dateStart:number,dateEnd:number):FeedingClass[]{
    let array = [];
    if(feedings && feedings.length>0){
      for(var i = 0; i < feedings.length; i++){
        let feeding = feedings[i];
        if(feeding.date < dateStart || feeding.date > dateEnd)
          continue;
        array.push(feeding);
      }
    }
    return array;
  },

  countFeedingsWithAlert(feedings:FeedingClass[]):number {
    // nombre de repas ayant ce plat, qui sont en warn ou danger
    let feedingsAlertsCount = 0;
    if(feedings && feedings.length>0){
      for(var i = 0; i < feedings.length; i++){
        let feeding:FeedingClass = feedings[i];
        if(feeding.alertLevel===LEVEL_CLASSNAMES.WARNING || feeding.alertLevel===LEVEL_CLASSNAMES.DANGER){
          feedingsAlertsCount++;
        }
      }
    }
    return feedingsAlertsCount;
  }
  

};
export default S_Cross;