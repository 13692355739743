import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques

// Composants propres
import S_Cross         from '../lib-services/S_Cross';
import IngredientClass   from '../lib-classes/IngredientClass';

// Composants primitves
import MyInlineLabel   from '../ui-primitives/MyInlineLabel';

// Styles
// import './IngredientAsInlineLabel.scss';

type IngredientAsInlineLabelProps = {
  /** Ingredient */
  ingredient: IngredientClass;
  /** Classname */
  className?: string;
}

/**
 * Affiche un label Ingredient.
 * @param props 
 * @returns {IngredientAsInlineLabel}
 */
function IngredientAsInlineLabel(props:IngredientAsInlineLabelProps) {

  // props
  let {ingredient,className} = props;

  // state

  // autres variables

  if(!ingredient)
    return null;



  const alertClassName = S_Cross.getIngredientAlertClass(ingredient);

  return (
    <MyInlineLabel 
      label={ingredient.label_fr}
      alertClassName={alertClassName}
      className={className} />
  );
}

export default IngredientAsInlineLabel;
