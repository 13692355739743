import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Button from '@mui/material/Button';

import AddIcon   from '@mui/icons-material/Add';

// Composants génériques
import S_Date     from '../../services/S_Date';

// Composants propres
import FeedingClass    from '../lib-classes/FeedingClass';
import FeedingsListOfADay from '../ui-lists/FeedingsListOfADay';

// Styles
// import './FeedingsListByDays.scss';

type FeedingsListByDaysProps = {
  feedings: FeedingClass[];
  addFeeding?: any;
  editFeeding?: any;
}

function FeedingsListByDays(props:FeedingsListByDaysProps) {

  // props
  let {feedings,addFeeding,editFeeding} = props;

  // state

  // autres variables

  let feedingsOrdered = S_Date.sortByDate(feedings).reverse();
  let feedingsOrderedAndGroupedByDay = S_Date.groupByDay(feedingsOrdered);
  let days = Object.keys(feedingsOrderedAndGroupedByDay);

  if(!days || days.length===0)
    return (
      <>
      <div className="text-center">
        Aucun repas enregistré.
      </div>
      {!!addFeeding ? (
      <div className="text-center mv-2">
        <Button variant="outlined" onClick={addFeeding} color="primary" startIcon={<AddIcon />}>Ajouter un repas</Button>
      </div>
      ) : null}
      </>
    );


  return (
    <>
    {!!addFeeding ? (
    <div className="text-center mt-2">
      <Button variant="outlined" onClick={addFeeding} color="primary" startIcon={<AddIcon />}>Ajouter un repas</Button>
    </div>
    ) : null}
    {!!days && days.length>0 ? (
    <div className="feedingsList">
      {days.map((dayInMillis,index) => {
        let feedings = feedingsOrderedAndGroupedByDay[dayInMillis];
        return (
          <FeedingsListOfADay key={dayInMillis}
            dayInMillis={parseInt(dayInMillis)}
            feedings={feedings}
            editFeeding={editFeeding} />
        );
      })}
    </div>
    ) : null}
    </>
  );
}

export default FeedingsListByDays;
