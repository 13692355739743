import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Button from '@mui/material/Button';

import AddIcon   from '@mui/icons-material/Add';

// Composants génériques
import S_App      from '../../services/S_App';

// Composants propres
import IngredientClass         from '../lib-classes/IngredientClass';
import IngredientAsCard         from '../ui-objects/IngredientAsCard';
import MyIngredientCard         from '../ui-primitives/MyIngredientCard';

// Styles
import './IngredientsList.scss';

type IngredientsListProps = {
  ingredients: IngredientClass[];
  addIngredient?: any;
  editIngredient?: any;
}

/**
 * 
 * @param props 
 * @returns {IngredientsList}
 */
function IngredientsList(props:IngredientsListProps) {

  // props
  let {ingredients,addIngredient,editIngredient} = props;

  // state

  // autres variables

  // Si liste vide
  if(!ingredients ||  ingredients.length===0)
    return (
      <>
      <div className="text-center">
        Aucun aliment enregistré.
      </div>
      <div className="text-center mv-2">
        <Button variant="outlined" onClick={addIngredient} color="primary" startIcon={<AddIcon />}>Ajouter un aliment</Button>
      </div>
      </>
    );

  // sinon
  let ingredientsOrdered = S_App.sortByLabel(ingredients);
  let ingredientsWithoutName = S_App.getByFilters(ingredients,[{attribute:'label_fr',value:undefined}]);

  return (
    <div className="ingredientsList">

      {ingredientsOrdered.map((ingredient,index) => {
        return (
          <IngredientAsCard key={index} 
            ingredient={ingredient}
            editIngredient={() => editIngredient(ingredient)} />
        );
      })}

      {ingredientsWithoutName.map((ingredient,index) => {
        return (
          <IngredientAsCard key={index} 
            ingredient={ingredient}
            editIngredient={() => editIngredient(ingredient)} />
        );
      })}

      <MyIngredientCard
        title={'Ajouter...'}
        mediumLabel={''}
        bottomLabel={'Cliquer pour ajouter...'}
        className={'action-card'}
        onClick={addIngredient} />
      
    </div>
  );
}

export default IngredientsList;
