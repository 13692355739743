import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques
import S_Date     from '../../services/S_Date';

// Composants propres
import S_Cross         from '../lib-services/S_Cross';
import IngredientClass   from '../lib-classes/IngredientClass';

// Composants primitves
import MyIngredientCard   from '../ui-primitives/MyIngredientCard';

// Styles
import './IngredientAsCard.scss';

type IngredientAsCardProps = {
  /** Ingredient */
  ingredient: IngredientClass;
  /** Callback au click */
  editIngredient?: any;
}

/**
 * Affiche une card Ingredient.
 * @param props 
 * @returns {IngredientAsCard}
 */
function IngredientAsCard(props:IngredientAsCardProps) {

  // props
  let {ingredient, editIngredient} = props;

  // state

  // autres variables

  if(!ingredient)
    return null;

  const lastFeeding = S_Cross.getLastFeedingOfIngredient(ingredient.idIngredient);
  const allFeedings = S_Cross.getAllFeedingsOfIngredient(ingredient.idIngredient);
  const feedingsAlertsCount = S_Cross.countFeedingsWithAlert(allFeedings);
  
  const ageLabel = ingredient.age !== undefined ? <i className="age"> (+{ingredient.age}m.)</i> : null;
  const title = <>{ingredient.label_fr}{ageLabel}</>;
  const mediumLabel = (
    <div>
      <small>
        {!!allFeedings && allFeedings.length>0 ? <>{allFeedings.length} repas, {feedingsAlertsCount} alerte(s)</> : <>&nbsp;</> }
      </small>
    </div>
  );
  let bottomLabel = <></>;
  // si on a un repas qui contient l'ingredient
  if(!!lastFeeding && lastFeeding.date!==undefined) {
    const delaiFromNow = S_Date.formatDelayForNow(lastFeeding.date, true);
    if(delaiFromNow > 0) {
      bottomLabel = <span>Dern. conso. il y a {delaiFromNow}j</span>;
    } else {
      bottomLabel = <span>Dern. conso. aujourd'hui</span>;
    }
  } 
  // sinon
  else {
    bottomLabel = <span>Jamais consommé</span>;
  }
  const alertClassName = S_Cross.getIngredientAlertClass(ingredient);


  return (
    <MyIngredientCard
      title={title}
      mediumLabel={mediumLabel}
      bottomLabel={bottomLabel}
      alertClassName={alertClassName}
      customFlag={ingredient.isCustom()}
      onClick={editIngredient} />
  );
}

export default IngredientAsCard;
