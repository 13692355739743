import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI

// Composants génériques
import S_Date     from '../../services/S_Date';

// Composants propres
import S_Ingredient         from '../lib-services/S_Ingredient';
import S_Barcode         from '../lib-services/S_Barcode';
import FeedingClass   from '../lib-classes/FeedingClass';

// Composants primitves
import MyFeedingLine   from '../ui-primitives/MyFeedingLine';
import IngredientAsInlineLabel from '../ui-objects/IngredientAsInlineLabel';
import BarcodeAsInlineLabel from '../ui-objects/BarcodeAsInlineLabel';
import MyInlineLabel from '../ui-primitives/MyInlineLabel';
import LEVEL_CLASSNAMES  from '../lib-enums/E_LevelClassNames';

// Styles
import './FeedingAsLine.scss';

type FeedingAsLineProps = {
  /** Feeding */
  feeding: FeedingClass;
  /** Callback au click */
  editFeeding?: any;
}

/**
 * Affiche une ligne repast.
 * @param props 
 * @returns {FeedingAsLine}
 */
function FeedingAsLine(props:FeedingAsLineProps) {

  // props
  let {feeding, editFeeding} = props;

  // state

  // autres variables

  if(!feeding)
    return null;

  const time = S_Date.formatTimeInString(feeding.date);
  const bottomLabel = <i>{feeding.notes}</i>;
  let alertClassName:LEVEL_CLASSNAMES = LEVEL_CLASSNAMES.UNDEFINED;
  if(feeding.alertLevel === 'success') alertClassName = LEVEL_CLASSNAMES.SUCCESS;
  if(feeding.alertLevel === 'warning') alertClassName = LEVEL_CLASSNAMES.WARNING;
  if(feeding.alertLevel === 'danger') alertClassName = LEVEL_CLASSNAMES.DANGER;

  const barcodes = (
    <>
    {!!feeding.idBarcodes && feeding.idBarcodes.length>0 ? (
      <div className="dayFeedingBarcodes">
        {feeding.idBarcodes.map((idBarcode) => {
          const barcode = S_Barcode.getById(idBarcode);
          if(!barcode)
            return null;
          return (
            <BarcodeAsInlineLabel key={barcode.idBarcode}
              barcode={barcode}
              className={'dayFeedingIngredient'} />
          );
        })}
      </div>
    ):(
      <MyInlineLabel 
        label={'Aucun plat'}
        disabled={true}
        className={'dayFeedingIngredient'} />
    )}
    </>
  );

  let mergedIngredients = !!feeding.idIngredients ? feeding.idIngredients : [];
  if(!!feeding.idBarcodes && feeding.idBarcodes.length>0){
    feeding.idBarcodes.forEach(idBarcode => {
      const barcode = S_Barcode.getById(idBarcode);
      if(!!barcode && !!barcode.idIngredients && barcode.idIngredients.length>0){
        barcode.idIngredients.forEach(idIngredient => {
          if(mergedIngredients.indexOf(idIngredient) === -1){
            mergedIngredients.push(idIngredient);
          }
        });
      }
    });
  }

  const ingredients = (
    <>
    {!!mergedIngredients && mergedIngredients.length>0 ? (
      <div className="dayFeedingIngredients">
        {mergedIngredients.map((idIngredient) => {
          let ingredient = S_Ingredient.getById(idIngredient);
          if(!ingredient)
            return null;
          return (
            <IngredientAsInlineLabel key={ingredient.idIngredient}
              ingredient={ingredient}
              className={'dayFeedingIngredient'} />
          );
        })}
      </div>
    ):(
    <MyInlineLabel 
      label={'Aucun aliment'}
      disabled={true}
      className={'dayFeedingIngredient'} />
    )}
    </>
  );

  return (
    <MyFeedingLine
      time={time}
      bottomLabel={bottomLabel}
      alertClassName={alertClassName}
      onClick={editFeeding}>
      {barcodes}
      {ingredients}
    </MyFeedingLine>
  );
}

export default FeedingAsLine;
