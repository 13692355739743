import S_App          from   '../../services/S_App';
import BarcodeClass from   '../lib-classes/BarcodeClass';
import {I_Barcode} from   '../lib-interfaces/I_Barcode';
import Papa from 'papaparse';

interface I_S_Barcode {
  STORAGE_KEY_BARCODE: string;
  barcodes: BarcodeClass[];

  fetch(cb): any;
  store(cb): any;

  getById(idBarcode:number): BarcodeClass;
  
  addRef(label_fr:string,trademark:string,idIngredients:number[],cb): BarcodeClass;
  update(value:undefined|number|string,field:string,barcode:BarcodeClass,cb): BarcodeClass;
  delete(ingredient:BarcodeClass,cb): void;
};

/**
 * @namespace
 * @folder Lib/Services
 * @type {Object}
 */
let S_Barcode: I_S_Barcode = {
  /**
   * @memberof S_Barcode
   * @property {string} STORAGE_KEY_BARCODE Clé de sauvegarde en localStorage des barcodes custom
   */
   STORAGE_KEY_BARCODE : 'barcodes',

  /** Liste des instances de BarcodeClass */
  barcodes : [],

  /**
   * Récupère les données de l'app (csv) et en localStorage, et contruit la liste des instances de BarcodeClass
   * @param cb Fonction de callback
   */
  fetch: (cb) => {
    let forceStore = false; // on restore after, car on a recup old datas

    // reset
    S_Barcode.barcodes = [];

    // A. references en dur
    let appBarcodeRefs: I_Barcode[] = [];

    fetch( './barcodeRefs.csv' )
      .then( response => response.text() )
      .then( responseText => {
        const fileContent = responseText;
        Papa.parse(fileContent, {
          header: true,
          delimiter: ';',
          complete: results => {
            results.data.forEach(element => {
              // si l'element est vide, on le garde pas
              if(!!element['idBarcode'] && element['idBarcode']!==undefined){
                let el = {
                  idBarcode: parseInt(element['idBarcode']),
                  label_fr: element['label_fr'],
                  trademark: element['trademark'],
                  age: parseInt(element['age']),
                  idIngredients: []
                };
                if(!!element['idIngredients']){
                  element['idIngredients'].split(',').forEach(val => {
                    el.idIngredients.push(parseInt(val));
                  });
                }
                appBarcodeRefs.push(el);
              }
              
            });
            if(S_App.isLogDebug) console.log('-- S_Barcode.tsx -- fetch() appBarcodeRefs',appBarcodeRefs);


            // B. references custom (localStorage)
            let customBarcodeRefs: I_Barcode[] = [];

            const locSto1 = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Barcode.STORAGE_KEY_BARCODE];
            let temporaryOldDatas = [];
            if(locSto1){
              temporaryOldDatas = JSON.parse(locSto1);
              if(S_App.isLogDebug) console.log('-- S_Barcode.tsx -- fetch() temporaryOldDatas',temporaryOldDatas);
              temporaryOldDatas.forEach(element => {
                // recup ancien format
                const elementID = element.id!==undefined ? element.id : element.idBarcode;
                if(element.id!==undefined) forceStore = true;
                const elementIDIngredients = element.ingredients!==undefined ? element.ingredients : element.idIngredients;
                // si l'element est vide, on le garde pas
                if((!!element['idBarcode'] && element['idBarcode']!==undefined) || (element['label_fr']!==undefined)){
                  // si l'element existe deja dans appBarcodeRefs, pas la peine de le conserver dans les customs
                  const sameBarcodesInApp = appBarcodeRefs.filter(appEl => appEl.idBarcode === elementID);
                  if(!sameBarcodesInApp || sameBarcodesInApp.length===0){
                    // il n'existe pas, on l'ajoute aux customs
                    let el = {
                      idBarcode: elementID,
                      label_fr: element.label_fr,
                      trademark: element.trademark,
                      age: element.age,
                      idIngredients: []
                    };
                    elementIDIngredients.forEach(val => {
                      el.idIngredients.push(parseInt(val));
                    });
                    customBarcodeRefs.push(el);
                  }else{
                    forceStore = true;
                  }
                }else{
                  forceStore = true;
                }
              });
            }
            if(S_App.isLogDebug) console.log('-- S_Barcode.tsx -- fetch() customBarcodeRefs',customBarcodeRefs);

            // C. creation des instances de BarcodeClass
            appBarcodeRefs.forEach(element => {
              let obj = new BarcodeClass(element, false);
              S_Barcode.barcodes.push(obj);
            });
            customBarcodeRefs.forEach(element => {
              const idBarcode = element.idBarcode;
              // recherche de doublon avec les ref app : si on trouve le meme id ou le nom label_fr (todo), on l'enleve des custom
              const matchingAppBarcodeRefs = appBarcodeRefs.filter(c => c.idBarcode === idBarcode);
              if(!matchingAppBarcodeRefs || matchingAppBarcodeRefs.length===0){
                let obj = new BarcodeClass(element, true);
                S_Barcode.barcodes.push(obj);
              }else{
                // logiquement, ils ne seront pas re-enregistres
                forceStore = true;
              }
            });
              
            if(S_App.isLogDebug) console.log('-- S_Barcode.tsx -- fetch() barcodes',S_Barcode.barcodes);

            // si on a recupere les anciennes datas contexte, on re-enregistre
            if(forceStore){
              if(S_App.isLogDebug) console.log('-- S_Barcode.tsx -- fetch() datas update, store again');
              S_Barcode.store(cb);
            }else{
              if(cb)
                cb();
            }

          },
        });
      });
  },
  /**
   * Enregistre les donnees en localStorage
   * @param cb Fonction de callback
   */
  store: (cb) => {
    let newCustomBarcodeRefs = [];
    if(!!S_Barcode.barcodes){
      S_Barcode.barcodes.forEach(element => {
        if(element.isCustom()){
          newCustomBarcodeRefs.push(element.getRefObject());
        }
      });
    }
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Barcode.STORAGE_KEY_BARCODE] = JSON.stringify(newCustomBarcodeRefs);
    if(cb)
      cb();
  },

  /**
   * Renvoi l'objet BarcodeClass correspondant au parametre
   * @param id ID de la référence barcode
   * @returns {BarcodeClass}
   */
  getById: (idBarcode:number|string) : BarcodeClass => {
    const matchingBarcodeClasses = S_Barcode.barcodes.filter(element => element.idBarcode === idBarcode);
    return !!matchingBarcodeClasses && matchingBarcodeClasses.length>0 ? matchingBarcodeClasses[0] : undefined;
  },

  /**
   * Ajoute une reference custom
   * @param label_fr Libellé en francais
   * @param trademark Marque
   * @param cb Fonction callback
   * @retuns {BarcodeClass}
   */
  addRef(label_fr:string,trademark:string,idIngredients:number[],cb): BarcodeClass{
      
    const dateNow = (new Date()).getTime();
    const newRefObject = {
      idBarcode: dateNow,
      label_fr: label_fr,
      trademark: trademark,
      age: undefined,
      idIngredients: [...idIngredients]
    };
    const newBarcodeClass = new BarcodeClass(newRefObject,true);

    if(!S_Barcode.barcodes)
    S_Barcode.barcodes = [];
    S_Barcode.barcodes.push(newBarcodeClass);
    
    S_Barcode.store(cb);
    return newBarcodeClass;
  },
  /**
   * 
   * @param value nouvelle valeur a enregistrer pour l'attribut
   * @param field nom de l'attribut a modifier
   * @param barcode Barcode à modifier, instance de BarcodeClass
   * @param cb Fonction callback
   * @returns {BarcodeClass}
   */
  update(value:any,field:string,barcode:BarcodeClass,cb): BarcodeClass{
    if(!S_Barcode.barcodes || S_Barcode.barcodes.length===0 || !barcode){
      if(cb) cb();
      return;
    }
    
    let matchingBarcodes = S_Barcode.barcodes.filter(element => element.idBarcode === barcode.idBarcode);
    if(!matchingBarcodes || matchingBarcodes.length === 0){
      console.log('Cannot update barcode, idBarcode',barcode.idBarcode+'not found.');
      return;
    }

    matchingBarcodes[0][field] = value;
    
    S_Barcode.store(cb);
    return matchingBarcodes[0];
  },
  /**
   * Supprime le barcode
   * @param barcode Ingredient à supprimer, instance de BarcodeClass
   * @param cb Fonction callback
   */
  delete(barcode:BarcodeClass,cb){
    if(!S_Barcode.barcodes || S_Barcode.barcodes.length===0 || !barcode){
      if(cb) cb();
      return;
    }
    
    let matchingBarcodeIndex = undefined;
    S_Barcode.barcodes.forEach((element,index) => {
      if(element.idBarcode === barcode.idBarcode){
        matchingBarcodeIndex = index;
      }
    });
    if(matchingBarcodeIndex === -1){
      console.log('Cannot delete barcode, idIngredient',barcode.idBarcode+'not found.');
      return;
    }
    if(!S_Barcode.barcodes[matchingBarcodeIndex].isCustom()){
      console.log('Cannot delete barcode because it\'s an app reference.');
      return;
    }

    S_Barcode.barcodes.splice(matchingBarcodeIndex,1);

    S_Barcode.store(cb);
  },
};
export default S_Barcode;