import S_App          from   '../../services/S_App';
import IngredientClass from   '../lib-classes/IngredientClass';
import {I_IngredientRef,I_IngredientContext} from   '../lib-interfaces/I_Ingredient';
import Papa from 'papaparse';

interface I_S_Ingredient {
  STORAGE_KEY_INGREDIENT: string;
  STORAGE_KEY_INGREDIENT_CONTEXT: string;
  ingredients: IngredientClass[];

  fetch(cb): any;
  store(cb): any;

  getById(idIngredient:number|string): IngredientClass;
  getByLabel_fr(label_fr:string): IngredientClass;
  
  addRef(label_fr:string,cb): IngredientClass;
  update(value:undefined|number|string,field:string,ingredient:IngredientClass,cb): IngredientClass;
  delete(ingredient:IngredientClass,cb): void;
};

/**
 * @namespace
 * @folder Lib/Services
 * @type {Object}
 */
let S_Ingredient: I_S_Ingredient = {
  /**
   * @memberof S_Ingredient
   * @property {string} STORAGE_KEY_INGREDIENT Clé de sauvegarde en localStorage des ingredients custom
   */
  STORAGE_KEY_INGREDIENT : 'ingredients',
  /**
   * @memberof S_Ingredient
   * @property {string} STORAGE_KEY_INGREDIENT_CONTEXT Clé de sauvegarde en localStorage des contextes d'ingredients
   */
  STORAGE_KEY_INGREDIENT_CONTEXT : 'ingredients_context',

  /** Liste des instances d'IngredientClass */
  ingredients : [],

  /**
   * Récupère les données de l'app (csv) et en localStorage, et contruit la liste des instances d'IngredientClass
   * @param cb Fonction de callback
   */
  fetch: (cb) => {
    // if(S_App.isLogDebug) console.log('-- S_Ingredient.tsx -- fetch()...');
    let forceStore = false; // on restore after, car on a recup old datas

    // reset
    S_Ingredient.ingredients = [];

    // A. references en dur
    let appIngredientRefs: I_IngredientRef[] = [];

    fetch( './ingredientRefs.csv' )
      .then( response => response.text() )
      .then( responseText => {
        const fileContent = responseText;
        Papa.parse(fileContent, {
          header: true,
          delimiter: ';',
          complete: results => {
            results.data.forEach(element => {
              appIngredientRefs.push({
                idIngredient: parseInt(element['idIngredient']),
                label_fr: element['label_fr'],
                age: element['age']!=='' ? parseInt(element['age']) : undefined
              });
            });
            if(S_App.isLogDebug) console.log('-- S_Ingredient.tsx -- fetch() appIngredientRefs',appIngredientRefs);


            // B. references custom (localStorage)
            let customIngredientRefs: I_IngredientRef[] = [];

            const locSto1 = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Ingredient.STORAGE_KEY_INGREDIENT];
            let temporaryOldDatas = [];
            if(locSto1){
              temporaryOldDatas = JSON.parse(locSto1);
              if(S_App.isLogDebug) console.log('-- S_Ingredient.tsx -- fetch() temporaryOldDatas',temporaryOldDatas);
              temporaryOldDatas.forEach(element => {
                // recup ancien format
                const elementID = element.id!==undefined ? element.id : element.idIngredient;
                if(element.id!==undefined) forceStore = true;
                // si l'element existe deja dans appIngredientRefs, pas la peine de le conserver dans les customs
                const sameIngredientsInApp = appIngredientRefs.filter(appEl => appEl.idIngredient === elementID);
                if(!sameIngredientsInApp || sameIngredientsInApp.length===0){
                  // il n'existe pas, on l'ajoute aux customs
                  customIngredientRefs.push({
                    idIngredient: elementID,
                    label_fr: element.label_fr,
                    age: element.age
                  });
                }else{
                  forceStore = true;
                }
              });
            }
            if(S_App.isLogDebug) console.log('-- S_Ingredient.tsx -- fetch() customIngredientRefs',customIngredientRefs);

            // C. contextes associes
            let ingredientContexts: I_IngredientContext[] = [];

            var locSto2 = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Ingredient.STORAGE_KEY_INGREDIENT_CONTEXT];
            if(locSto2){
              ingredientContexts = JSON.parse(locSto2);
            }
            // si old datas => on transfo
            temporaryOldDatas.forEach(element => {
              if((!!element.notes && element.notes!=='') || (!!element.alertLevel && element.alertLevel!=='')){
                // console.log('Fetch ingredients, add context ',element);
                ingredientContexts.push({
                  idContext: undefined,
                  idIngredient: element.id,
                  notes: element.notes,
                  alertLevel: element.alertLevel
                });
                forceStore = true;
              }
            });
            if(S_App.isLogDebug) console.log('-- S_Ingredient.tsx -- fetch() ingredientContexts',ingredientContexts);

            // D. creation des instances de IngredientClass
            appIngredientRefs.forEach(element => {
              const idIngredient = element.idIngredient;
              // recherche du contexte associe
              const relatedContext = ingredientContexts.filter(c => c.idIngredient === idIngredient);
              let obj = new IngredientClass(element, false, !!relatedContext && relatedContext.length>0 ? relatedContext[0] : undefined);
              S_Ingredient.ingredients.push(obj);
            });
            customIngredientRefs.forEach(element => {
              const idIngredient = element.idIngredient;
              // recherche de doublon avec les ref app : si on trouve le meme id ou le nom label_fr (todo), on l'enleve des custom
              const matchingAppIngredientRefs = appIngredientRefs.filter(c => c.idIngredient === idIngredient);
              if(!matchingAppIngredientRefs || matchingAppIngredientRefs.length===0){
                // recherche du contexte associe
                const relatedContexts = ingredientContexts.filter(c => c.idIngredient === idIngredient);
                let obj = new IngredientClass(element, true, !!relatedContexts && relatedContexts.length>0 ? relatedContexts[0] : undefined);
                S_Ingredient.ingredients.push(obj);
              }else{
                // logiquement, ils ne seront pas re-enregistres
                forceStore = true;
              }
            });
              
            if(S_App.isLogDebug) console.log('-- S_Ingredient.tsx -- fetch() ingredients',S_Ingredient.ingredients);

            // si on a recupere les anciennes datas contexte, on re-enregistre
            if(forceStore){
              if(S_App.isLogDebug) console.log('-- S_Ingredient.tsx -- fetch() datas update, store again');
              S_Ingredient.store(cb);
            }else{
              if(cb)
                cb();
            }

          },
        });
      });
  },
  /**
   * Enregistre les donnees en localStorage
   * @param cb Fonction de callback
   */
  store: (cb) => {
    let newCustomIngredientRefs = [];
    let newIngredientContexts = [];
    if(!!S_Ingredient.ingredients){
      S_Ingredient.ingredients.forEach(element => {
        if(element.isCustom){
          newCustomIngredientRefs.push(element.getRefObject());
        }
        newIngredientContexts.push(element.getContextObject());
      });
    }
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Ingredient.STORAGE_KEY_INGREDIENT] = JSON.stringify(newCustomIngredientRefs);
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Ingredient.STORAGE_KEY_INGREDIENT_CONTEXT] = JSON.stringify(newIngredientContexts);
    if(cb)
      cb();
  },

  /**
   * Renvoi l'objet IngredientClass correspondant au parametre
   * @param id ID de la référence ingredient
   * @returns {IngredientClass}
   */
  getById: (idIngredient:number|string) : IngredientClass => {
    const matchingIngredientClasses = S_Ingredient.ingredients.filter(element => element.idIngredient === idIngredient);
    return !!matchingIngredientClasses && matchingIngredientClasses.length>0 ? matchingIngredientClasses[0] : undefined;
  },
  /**
   * Renvoi l'objet IngredientClass correspondant au parametre
   * @param label_fr libellé en francais
   * @returns {IngredientClass}
   */
  getByLabel_fr: (label_fr:string) : IngredientClass => {
    const matchingIngredientClasses = S_Ingredient.ingredients.filter(element => element.label_fr.toLowerCase().trim() === label_fr.toLowerCase().trim());
    return !!matchingIngredientClasses && matchingIngredientClasses.length>0 ? matchingIngredientClasses[0] : undefined;
  },

  /**
   * Ajoute une reference custom
   * @param label_fr Libellé en francais
   * @param cb Fonction callback
   * @returns {IngredientClass}
   */
  addRef(label_fr:string,cb):IngredientClass{
      
    const dateNow = (new Date()).getTime();
    const newRefObject = {
      idIngredient: dateNow,
      label_fr: label_fr
    };
    const newIngredientClass = new IngredientClass(newRefObject,true,undefined);

    if(!S_Ingredient.ingredients)
      S_Ingredient.ingredients = [];
    S_Ingredient.ingredients.push(newIngredientClass);
    
    S_Ingredient.store(cb);
    return newIngredientClass;
  },
  /**
   * 
   * @param value nouvelle valeur a enregistrer pour l'attribut
   * @param field nom de l'attribut a modifier
   * @param ingredient Ingredient à modifier, instance d'IngredientClass
   * @param cb Fonction callback
   * @returns {IngredientClass}
   */
  update(value:any,field:string,ingredient:IngredientClass,cb):IngredientClass{
    if(!S_Ingredient.ingredients || S_Ingredient.ingredients.length===0 || !ingredient){
      if(cb) cb();
      return;
    }
    
    let matchingIngredients = S_Ingredient.ingredients.filter(element => element.idIngredient === ingredient.idIngredient);
    if(!matchingIngredients || matchingIngredients.length === 0){
      console.log('Cannot update ingredient, idIngredient',ingredient.idIngredient+'not found.');
      return;
    }

    matchingIngredients[0][field] = value;
    
    S_Ingredient.store(cb);
    return matchingIngredients[0];
  },
  /**
   * Supprime l'ingredient
   * @param ingredient Ingredient à supprimer, instance d'IngredientClass
   * @param cb Fonction callback
   */
  delete(ingredient:IngredientClass,cb){
    if(!S_Ingredient.ingredients || S_Ingredient.ingredients.length===0 || !ingredient){
      if(cb) cb();
      return;
    }
    
    let matchingIngredientIndex = undefined;
    S_Ingredient.ingredients.forEach((element,index) => {
      if(element.idIngredient === ingredient.idIngredient){
        matchingIngredientIndex = index;
      }
    });
    if(matchingIngredientIndex === -1){
      console.log('Cannot delete ingredient, idIngredient',ingredient.idIngredient+'not found.');
      return;
    }
    if(!S_Ingredient.ingredients[matchingIngredientIndex].isCustom()){
      console.log('Cannot delete ingredient because it\'s an app reference.');
      return;
    }
    S_Ingredient.ingredients.splice(matchingIngredientIndex,1);

    S_Ingredient.store(cb);
  },
};
export default S_Ingredient;