import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@mui/material/Container';
import Fab       from '@mui/material/Fab';

import AddIcon   from '@mui/icons-material/Add';


// Composants génériques

// Composants propres
import IngredientClass         from '../lib-classes/IngredientClass';
import IngredientsList         from '../ui-lists/IngredientsList';

// Styles
import './PanelIngredients.scss';

type PanelIngredientsProps = {
  ingredients: IngredientClass[];
  /** Fonction de demande d'ajout d'un ingredient */
  addIngredient: any;
  /** Fonction de demande d'édition d'un ingredient */
  editIngredient: any;
}

function PanelIngredients(props:PanelIngredientsProps) {

  // props
  let {ingredients,addIngredient,editIngredient} = props;

  // state

  // chargement des donnees

  // autres variables

  return (
    <Container fixed className="panel-contents pb-6">

      <div className="mt-vague">
        <IngredientsList
          ingredients={ingredients}
          addIngredient={addIngredient}
          editIngredient={editIngredient} />
      </div>
      
      <div className="panel-contents-footer text-right">
        <Fab aria-label="Ajouter" onClick={addIngredient} className="mh-2" color="primary"><AddIcon /></Fab>
      </div>

    </Container>
  );
}

export default PanelIngredients;
