import React from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Button from '@mui/material/Button';

import AddIcon   from '@mui/icons-material/Add';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

// Composants génériques
import S_App      from '../../services/S_App';

// Composants propres
import BarcodeClass         from '../lib-classes/BarcodeClass';
import BarcodeAsCard         from '../ui-objects/BarcodeAsCard';
import MyBarcodeCard         from '../ui-primitives/MyBarcodeCard';

// Styles
import './BarcodesList.scss';

type BarcodesListProps = {
  barcodes: BarcodeClass[];
  addBarcode?: any;
  scanBarcode?: any;
  editBarcode?: any;
}

/**
 * 
 * @param props 
 * @returns {BarcodesList}
 */
function BarcodesList(props:BarcodesListProps) {

  // props
  let {barcodes,addBarcode,scanBarcode,editBarcode} = props;

  // state

  // autres variables

  // Si liste vide
  if(!barcodes ||  barcodes.length===0)
    return (
      <>
      <div className="text-center">
        Aucun plat enregistré.
      </div>
      {!!addBarcode ? (
      <div className="text-center mv-2">
        <Button variant="outlined" onClick={addBarcode} color="primary" startIcon={<AddIcon />}>Ajouter un code barre</Button>
      </div>
      ) : null}
      {!!scanBarcode ? (
      <div className="text-center mv-2">
        <Button variant="outlined" onClick={scanBarcode} color="primary" startIcon={<DocumentScannerIcon />}>Scanner un code barre</Button>
      </div>
      ) : null}
      </>
    );

  // sinon
  let barcodesOrdered = S_App.sortByLabel(barcodes);
  let barcodesWithoutName = S_App.getByFilters(barcodes,[{attribute:'label_fr',value:undefined}]);

  return (
    <div className="barcodesList">

      {barcodesOrdered.map((barcode,index) => {
        return (
          <BarcodeAsCard key={index}
            barcode={barcode}
            editBarcode={() => editBarcode(barcode)} />
        );
      })}

      {barcodesWithoutName.map((barcode,index) => {
        return (
          <BarcodeAsCard key={index}
            barcode={barcode}
            editBarcode={() => editBarcode(barcode)} />
        );
      })}

      <MyBarcodeCard
        title={'Ajouter'}
        bottomLabel={'Cliquer pour ajouter...'}
        className={'action-card'}
        onClick={addBarcode}>
      </MyBarcodeCard>

    </div>
  );
}

export default BarcodesList;
