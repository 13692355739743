import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

// Composants génériques

// Composants propres

// Styles
// import './DialogAddIngredient.scss';

type DialogAddIngredientProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickValidate: any;
}

function DialogAddIngredient(props:DialogAddIngredientProps) {

  // props
  let {isDialogOpened,closeDialog,onClickValidate} = props;

  // state
  const [label_fr, setLabel_fr] = useState('Nouveau');

  function changeLabel_fr(value){
    setLabel_fr(value);
  }


  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus>
      <DialogTitle id="alert-dialog-title">Ajouter un aliment</DialogTitle>
      <DialogContent>

        <div className="mv-1">
          <TextField id="label_fr" label={'Nom'} type="text" fullWidth size="small" autoFocus={true}
            value={label_fr}
            onChange={(e) => changeLabel_fr(e.target.value)} />
        </div>

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Annuler</Button>
        <Button onClick={(e) => onClickValidate(label_fr)} color="primary">Valider</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogAddIngredient;
