import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import S_Cross      from '../lib-services/S_Cross';
import S_Ingredient      from '../lib-services/S_Ingredient';
import IngredientClass    from '../lib-classes/IngredientClass';
import FeedingClass       from '../lib-classes/FeedingClass';
import MyFlag       from '../ui-primitives/MyFlag';
import LEVEL_CLASSNAMES  from '../lib-enums/E_LevelClassNames';

// Styles
// import './PanelCalendar.scss';

type PanelCalendarProps = {
  ingredients: IngredientClass[];
  feedings: FeedingClass[];

  togglePanelDrag: any;
}

function PanelCalendar(props:PanelCalendarProps) {

  // props
  let {ingredients,feedings,togglePanelDrag} = props;

  let storedIngredientsOrdered = S_App.sortByLabel(ingredients);
  let allAsId = [];
  for(var i = 0; i < storedIngredientsOrdered.length; i++){
    let ingredient = storedIngredientsOrdered[i];
    allAsId.push(ingredient.id);
  }

  // state
  const [selectedIngredientsId, setSelectedIngredientsId] = useState(allAsId);

  // chargement des donnees

  // autres variables
  // let storedIngredientsOrdered = S_App.sortByLabel(ingredients);
  let feedingsOrdered = S_Date.sortByDate(feedings);


  function changeIngredients(value){
    // value est un array de number
    console.log('changeIngredients',value);
    setSelectedIngredientsId(value);
  }
  function selectAll(){
    let storedIngredientsOrdered = S_App.sortByLabel(ingredients);

    let allAsId = [];
    for(var i = 0; i < storedIngredientsOrdered.length; i++){
      let ingredient = storedIngredientsOrdered[i];
      allAsId.push(ingredient.idIngredient);
    }

    setSelectedIngredientsId(allAsId);
  }
  function deselectAll(){
    setSelectedIngredientsId([]);
  }


  // Depuis 7j
  let NOW = (new Date()).getTime();
  let dateStart = NOW - (1000*60*60*24*7);  // ya 7j
  let dateEnd = NOW;
  let feedingsOfWeek = S_Cross.getFeedingsBetween(feedings,dateStart,dateEnd);
  let ingredientsOfWeek = [];
  if(feedingsOfWeek.length>0){
    for(var i = 0; i < feedingsOfWeek.length; i++){
      let feeding = feedingsOfWeek[i];
      let feedingIngredients = feeding.idIngredients;
      if(!!feedingIngredients && feedingIngredients.length>0){
        for(var j = 0; j < feedingIngredients.length; j++){
          let feedingIngredient = feedingIngredients[j];
          if(ingredientsOfWeek.indexOf(feedingIngredient)===-1)
            ingredientsOfWeek.push(feedingIngredient);
        }
      }
    }
  }

  return (
    <Container fixed className="panel-contents pb-6">

      <div className="mt-vague">

        <h2 className="primary">Légende</h2>
        <div className="mb-2">
          <div><MyFlag alertClassName={LEVEL_CLASSNAMES.LIGHTSUCCESS} first={true} /> Pas d'alerte, 1ère consommation</div>
          <div><MyFlag alertClassName={LEVEL_CLASSNAMES.LIGHTSUCCESS} /> Pas d'alerte</div>
          <div><MyFlag alertClassName={LEVEL_CLASSNAMES.SUCCESS} first={true} /> OK, 1ère consommation</div>
          <div><MyFlag alertClassName={LEVEL_CLASSNAMES.SUCCESS} /> OK</div>

        </div>

        <h2 className="primary">Depuis 7j</h2>
        <div className="mb-4">
          <div className="overflow">
            <table className="calendar"
              onMouseDown={togglePanelDrag} 
              onMouseUp={togglePanelDrag}
              onTouchStart={togglePanelDrag}
              onTouchEnd={togglePanelDrag}>
              <tbody>
                {ingredientsOfWeek.map((ingredientID,index) => {
                  let ingredient = S_Ingredient.getById(ingredientID);
                  if(!ingredient)
                    return null;

                  const alertClassName = S_Cross.getIngredientAlertClass(ingredient);
                  let allFeedingsOfIngredient = S_Cross.getAllFeedingsOfIngredient(ingredient.idIngredient);
                  let firstFeeding = S_Cross.getFirstFeedingOfIngredient(ingredient.idIngredient);

                  return (
                    <tr key={index}>
                      <th align="left" className={allFeedingsOfIngredient.length>0 ? '' : 'unused'}>{ingredient.label_fr}</th>
                      {feedingsOrdered.map((feeding,index2) => {
                        if(feeding.date < dateStart || feeding.date > dateEnd)
                          return null;
                        return (
                          <td key={index2} className="text-center">
                            {feeding.idIngredients.indexOf(ingredient.idIngredient)!==-1 ? (
                              <MyFlag 
                                alertClassName={alertClassName}
                                first={!!firstFeeding && firstFeeding.idFeeding===feeding.idFeeding} />
                            ) : (
                              <div>&nbsp;</div>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>&nbsp;</th>
                  {feedingsOrdered.map((feeding,index2) => {
                    let day = S_Date.formatDateInString(feeding.date);
                    let time = S_Date.formatTimeInString(feeding.date);

                    if(feeding.date < dateStart || feeding.date > dateEnd)
                      return null;
                    return (
                      <td key={index2} className="text-center">
                        <small>{day} {time}</small>
                      </td>
                    );
                  })}
                </tr>
              </tfoot>
            </table>
          </div>
        </div>





        <h2 className="primary">Personnalisable</h2>
        {!!storedIngredientsOrdered && storedIngredientsOrdered.length>0 && !!feedingsOrdered && feedingsOrdered.length>0 ? (
          <div className="filters clear-after">
            <div className="text-left">
              <div>Afficher les aliments</div>
              <FormControl fullWidth>
                <Select labelId="filtersIngredients" id="filtersIngredients" value={selectedIngredientsId} label="Aliments" onChange={(e) => changeIngredients(e.target.value)} multiple={true}>
                  {storedIngredientsOrdered.map((storedIngredient,index) => {
                    return (
                      <MenuItem key={index} value={storedIngredient.id}>{storedIngredient.label_fr}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <div className="float-left" onClick={selectAll}>Tout sélectionner</div>
              <div className="float-right" onClick={deselectAll}>Tout vider</div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            {!storedIngredientsOrdered || storedIngredientsOrdered.length===0 ? (
              <div>Aucun aliment enregistré.</div>
            ) : null}
            {!feedingsOrdered || feedingsOrdered.length===0 ? (
              <div>Aucun repas enregistré.</div>
            ) : null}
          </div>
        )}

        
        {selectedIngredientsId.length>0 ? (
        <div className="overflow">
          <table className="calendar"
            onMouseDown={togglePanelDrag} 
            onMouseUp={togglePanelDrag}
            onTouchStart={togglePanelDrag}
            onTouchEnd={togglePanelDrag}>
            <tbody>
              {selectedIngredientsId.map((ingredientID,index) => {
                let ingredient = S_Ingredient.getById(ingredientID);
                if(!!ingredient){
                  const alertClassName = S_Cross.getIngredientAlertClass(ingredient);
                  let allFeedingsOfIngredient = S_Cross.getAllFeedingsOfIngredient(ingredient.idIngredient);
                  let firstFeeding = S_Cross.getFirstFeedingOfIngredient(ingredient.idIngredient);

                  return (
                    <tr key={index}>
                      <th align="left" className={allFeedingsOfIngredient.length>0 ? '' : 'unused'}>{ingredient.label_fr}</th>
                      {feedingsOrdered.map((feeding,index2) => {
                        return (
                          <td key={index2} className="text-center">
                            {feeding.ingredients.indexOf(ingredient.idIngredient)!==-1 ? (
                              <MyFlag 
                                alertClassName={alertClassName}
                                first={!!firstFeeding && firstFeeding.idFeeding===feeding.idFeeding} />
                            ) : (
                              <div>&nbsp;</div>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
            <tfoot>
              <tr>
                <th>&nbsp;</th>
                {feedingsOrdered.map((feeding,index2) => {
                  let day = S_Date.formatDateInString(feeding.date);
                  let time = S_Date.formatTimeInString(feeding.date);
                  return (
                    <td key={index2} className="text-center">
                      <small>{day} {time}</small>
                    </td>
                  );
                })}
              </tr>
            </tfoot>
          </table>
        </div>
        ) : (
        <div className="text-center">
          Sélectionner au moins un aliment dans la liste.
        </div>
        )}

        
        
      </div>

    </Container>
  );
}

export default PanelCalendar;
